<template>
    <transition name="slide-fade">
        <div v-if="$store.state.preloader" class="preloader">
            <img src="@/assets/logo.png">
        </div>
    </transition>
</template>

<script>
export default {
    name: "Preloader"
}
</script>

<style lang="less" scoped>

@import "../less/_variables";

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: @sidebar-bg-new;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 150px;
        transform: scale(1);
        animation: pulse 1s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.9);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.9);
        }
    }
}

.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
    //transform: translateX(100px);
    opacity: 0;
}

.theme-dark {
    .preloader {
        background-color: @dark-purple;
    }
}
</style>
