<template>
    <div id="map">
    </div>
</template>

<script>

import "leaflet";
import "leaflet-fullscreen";
import "leaflet.markercluster";
import ukJSON from "../assets/ukraine.geo.json";
import $ from "jquery";
import Color from "../classess/Color";
import Helper from "../classess/Helper"
import {mapState} from "vuex";
import types from "../store/types";

export default {
    name: "DevicesMap",
    props: {
        devices: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            mapHeight: null,
            map: null,
            currentDevice: null,
            icons: {
                danger: require("../assets/marker-icon-2x-red.png"),
                info: require("../assets/marker-icon-2x-orange.png"),
                primary: require("../assets/marker-icon-2x-blue.png"),
                success: require("../assets/marker-icon-2x-green.png"),
            },
        }
    },
    methods: {
        getMetricTimeseries(devEui, metricSlug, metricPortIndex = 0) {
            const deviceMetrics = this.cardsTimeseriesCache?.find(item => item.devEui === devEui);
            if (!deviceMetrics) {
                return null;
            }
            const metrics = deviceMetrics.timeseries.filter(item => item.getSlug() === metricSlug);

            return metrics[metricPortIndex || 0];
        },
        createMarker(device) {
            let html = '';
            const metricData = []
            let metrics = device.getType().getMetricsWithPortsExclude('bat_volt')
                metrics.forEach(metric => {
                    metricData.push({
                        value: this.getMetricTimeseries(device.getDevEui(), metric.getSlug(), metric.getPortIndex())?.getLatestData()?.getValue() || "n/a",
                        icon: require(`@/assets/icons/${metric?.getIcon()}.svg`),
                        unit: metric.getUnits()
                    });
                });
                // language=HTML
                html = `
                <div id="${device.getId()}" class="d-flex justify-content-center align-items-center marker">
                    <div class="font-weight-bold app-marker-name">
                        <div>
                            <div class="app-marker-name-title">${Helper.stringLimit(device.getName(), 30)}</div>
                            <div class="metric-container">
                                <hr>
                                ${metricData?.map(metric => {return `
                                    <div class="app-marker-name-metric w-100">
                                        <img class="d-block" width="15" src="${metric.icon}" alt="">
                                        <div>${metric.value} ${metric.unit}</div>
                                    </div>
                                `}).join('')}
                            </div>
                        </div>
                        <span class="arrow-top"></span>
                    </div>
                </div>
            `;

            return new L.Marker([device.getLatitude(), device.getLongitude()], {
                riseOnHover: true,
                icon: new L.DivIcon({
                    className: 'app-marker-wrapper',
                    html: html || `<div class="app-marker-empty">N/A</div>`
                })
            }).setZIndexOffset(html.length ? 20 : 10);
        },
        // turnMarkerMetrics(e) {
        //     let wrapper = $(e.originalEvent.target).hasClass('app-marker-wrapper')
        //         ? $(e.originalEvent.target)
        //         : $(e.originalEvent.target).parents('.app-marker-wrapper');
        //
        //     let offset = 0;
        //
        //     wrapper.find('.app-marker').each(function () {
        //         $(this).css({
        //             top: offset,
        //             left: offset
        //         });
        //
        //         offset -= 2;
        //     });
        // },
        // expandMarkerMetrics(e) {
        //     let wrapper = $(e.originalEvent.target).hasClass('app-marker-wrapper')
        //         ? $(e.originalEvent.target)
        //         : $(e.originalEvent.target).parents('.app-marker-wrapper');
        //
        //     let startOffset = (wrapper.find('.app-marker').length - 1) * 45;
        //     let startOffsetName = (wrapper.find('.app-marker-name').length - 1) * 45;
        //     wrapper.find('.app-marker').each(function () {
        //         $(this).css({
        //             top: -startOffset,
        //             left: 0
        //         });
        //
        //         startOffset -= 45;
        //     });
        //     wrapper.find('.app-marker-name').each(function () {
        //         $(this).css({
        //             top: -startOffsetName,
        //             left: -80
        //         }).attr("data-content", "qwerqrtt");
        //
        //         startOffsetName -= 45;
        //     });
        // },
        renderMap() {
            let markersList = [];

            this.map = L.map('map', {
                scrollWheelZoom: window.outerWidth > 768,
                fullscreenControl: true,
            }).setView([47.8421933, 31.6945746], 6);

            L.tileLayer(this.$store.state.tileApi, {
                maxZoom: 19,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
            }).addTo(this.map);

            // border Ukraine
            L.geoJSON(ukJSON, {
                fillColor: "#8417b1",
                color: "#8B7FE8",
                opacity: 1,
                fillOpacity: 0
            }).addTo(this.map);

            // add markers (devices)
            this.devices.forEach(device => {
                const marker = this.createMarker(device)
                marker.on("click", () => {
                    this.$router.push({name: "Device", params: {id: device.getId()}})
                });

                // marker.on('mouseover', this.expandMarkerMetrics);
                // marker.on('mouseout', this.turnMarkerMetrics);

                this.map.addLayer(marker);
                markersList.push(marker);
                if(device.hasActiveAccidents()) {
                    const marker = document.getElementById(device.getId());
                    marker.classList.add('activeAccident')
                }
            });

            if (markersList.length) {
                let group = new L.featureGroup(markersList);
                this.map.fitBounds(group.getBounds().pad(window.outerWidth <= 768 ? 1 : 0.7));
            }
        },
    },
    computed: {
        ...mapState({
            cardsTimeseriesCache: state => state.cardsTimeseriesCache
        }),
        zoom() {
            return this.map?.getZoom()
        }
    },
    watch: {
        devices() {
            this.renderMap();
            this.$emit("ready");
        },
        zoom(val) {
            const markers = document.querySelectorAll('.marker')
            if(val < 16) {
                markers.forEach(marker => {
                    marker.style.opacity = 0
                })
            } else {
                markers.forEach(marker => {
                    marker.style.opacity = 1
                })
            }
        }
    },
    mounted() {
        this.renderMap();
        this.$emit("ready");
    }

}
</script>

<style lang="less">

@import "../less/_variables";

#map {
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    border-radius: 0.5rem;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.1);

    @media (max-width: 768px) {
        height: calc(100vh - 250px);
    }
}

.app-marker-wrapper {
    position: relative;
}
.app-marker {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;

    &-name {
        font-size: 12px;
        position: relative;
        z-index: 999;
        background: #FFFFFF;
        padding: 10px;
        border-radius: 10px;
        white-space: nowrap;
        &:after {
            content: '';
            top:0; left:0; right:0; bottom:0;
            position: absolute;
            border-radius: 10px;
            box-shadow: 0px 5px 24px rgba(51, 51, 51, 0.3);
        }

        &:hover {
            .metric-container {
                display: block;
            }
        }

        img {
            margin-right: 5px;
        }

        hr {
            margin: 5px 0;
        }

        &-title {
            text-align: center;
        }

        &-metric {
            margin-bottom: 3px;
            display: flex;
            align-items: center;
        }

        .arrow-top {
            border: 7px solid #FFFFFF;
            position: absolute;
            left: calc(50% - 7px);
            top: -7px;
            z-index: 998;
            transform: rotate(45deg);
        }
    }
}

.metric-container {
    display: none;
}

.activeAccident {
    .app-marker-name:after {
        box-shadow: 0 0 17px #E94C3D;
        animation: pulse_red .6s ease-out infinite;
    }
}

.app-marker-empty {
    .app-marker();
    background: #ff0000;
}

</style>
