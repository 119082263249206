<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div ref="modalDialog" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Добавление контакта') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened" class="modal-body">
                    <div class="input-group mb-4">
                        <input v-show="newContact.type === 'phone'"
                               @input="setValue"
                               type="text" ref="phoneInput" class="form-control fix-input" placeholder="+38 (___) ___-__-__">

                        <input v-show="newContact.type === 'email'"
                               @input="setValue"
                               type="email" class="form-control fix-input" ref="emailInput" placeholder="example@mail.com">

                        <input v-show="newContact.type !== 'phone' && newContact.type !== 'email'"
                               @input="setValue"
                               type="text" class="form-control fix-input" ref="otherInput" placeholder="">

                        <div class="input-group-append">
                            <button class="btn btn-purple dropdown-toggle" style="width: 130px;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ getContactTypeNameByType(newContact.type) }}
                            </button>
                            <div class="dropdown-menu app-dropdown">
                                <a v-for="item in availableContactTypes"
                                   :key="item.type"
                                   @click.prevent="newContact.type = item.type"
                                   href="#"
                                   class="dropdown-item">
                                    <span class="d-inline-block" style="width: 30px;">
                                        <i v-if="newContact.type === item.type" class="fas fa-check text-purple"></i>
                                    </span>
                                    {{ item.text }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="input-group">
                        <div class="row w-100 align-items-center">
                            <div class="col-auto">
                                <toggle-button :sync="true"
                                               class="rounded"
                                               :color="toggleColor"
                                               :width="34"
                                               :height="14"
                                               :switch-color="switchColor"
                                               :value="newContact.auth"
                                               @change="e => newContact.auth = e.value"/>
                            </div>
                            <div class="col-auto font-weight-bold" :class="{ 'text-black-50' : !newContact.auth }">
                                {{ $t('Разрешить авторизацию') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="create"
                            :disabled="processing || !valid"
                            class="btn btn-purple btn-block">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Добавить контакт') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Inputmask from "inputmask";
import _ from "underscore";
import $ from "jquery";
import {mapState} from 'vuex';
import ContactService from "../../services/ContactService";
import config from "../../config";

let Contact = new ContactService()

export default {
    name: "ContactAddModal",
    props: ["opened"],
    data() {
        return {
            processing: false,
            newContact: {
                value: "",
                auth: false,
                type: "phone",
                comment: ""
            }
        }
    },
    methods: {
        setValue(e) {
            this.newContact.value = e.target.value;
        },
        getContactTypeNameByType(type) {
            return _.findWhere(this.availableContactTypes, {type: type})?.text;
        },
        async create() {

            const contact = Object.assign(this.newContact, {
                value: this.newContact.type === "phone"
                    ? this.newContact.value.replace(/\D+/g, "")
                    : this.newContact.value
            })

            this.processing = true;
            const result = await Contact.create(contact);
            this.processing = false;

            this.newContact = {
                value: '',
                auth: false,
                type: 'phone',
                comment: ''
            };
            if (result) {
                this.$toast.success(this.$t('Контакт добавлен'));
                Object.assign(this.$data, this.$options.data());
                $(this.$refs.modal).modal("hide");
            } else {
                this.$toast.error(this.$t('Ошибка! Возможно такой контакт уже есть'));
            }
        },
    },
    computed: {
        ...mapState({
            availableContactTypes: state => state.availableContactTypes,
        }),
        toggleColor() {
            return config.toggleColor;
        },
        switchColor() {
            return config.switchColor;
        },
        valid() {
            if (this.newContact.type === "email") {
                return /\S+@\S+\.\S+/.test(this.newContact.value);
            } else if (this.newContact.type === "phone") {
                return this.newContact.value.replace(/\D+/g, "").length === 12;
            } else {
                return this.newContact.value.length > 0;
            }
        },

    },
    watch: {
        "newContact.type"() {
            this.newContact.value = "";
            this.$refs.phoneInput.value = "";
            this.$refs.emailInput.value = "";
            this.$refs.otherInput.value = "";
        },
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
                setTimeout(() => {
                    Inputmask({"mask": "+38 (999) 999-99-99",}).mask(this.$refs.phoneInput);
                })
            } else {
                Object.assign(this.$data, this.$options.data());
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style scoped>

.fix-input {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
}

</style>
