<template>
    <div>
        <div v-for="item in getAccessMetrics"
            :key="item.getId()"
            class="py-1 px-2">
            <div class="d-flex">
                <div class="mr-3">
                    <Icon :iconName="item.getIcon()" :fill="item.getColor()" height="24" width="24"/>
                </div>
                <div class="font-weight-500">
                    {{ stringLimit(item.getName(), 30) }}
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import _ from "underscore";
import DeviceObject from "../objects/DeviceObject";
import Icon from "./Icon";
import Helper from "../classess/Helper";

export default {
    name: "DeviceAccessTableItemMetrics",
    components:{
        Icon
    },
    props: {
        access: {
            type: Object,
            required: true
        },
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    methods: {
        stringLimit: Helper.stringLimit,
    },
    computed: {
        getAccessMetrics() {
            return _.chain(this.access?.metrics)
                .map(item => {
                    return this.deviceObject.getType().getMetricBySlug(item.name);
                })
                .filter(item => item)
                .value()
        },
    }
}
</script>

<style scoped>

</style>
