<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div ref="modalDialog" class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content app-modal-content" style="min-height: 500px;">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Редактирование аварии') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened" class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="font-weight-bold text-muted">
                                    {{ $t('Название аварии') }}
                                    <strong class="text-danger">*</strong></label>
                                <input v-model="title" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-12 mb-4">
                            <ul class="nav nav-tabs">
                                <li v-for="item in levels" :key="item.level"
                                    @click="currentLevelTab = item.level"
                                    class="nav-item">
                                    <a class="nav-link font-weight-bold"
                                       :style="{ color: getLevelColor(item.level) }"
                                       :class="{ active : currentLevelTab === item.level }" href="#">
                                        {{ item.levelName }}
                                        ({{ countLevelConditions(item.level) }})
                                        <i @click.stop="removeLevel(item)"
                                           v-if="currentLevelTab === item.level"
                                           class="fas fa-times-circle text-dark"></i>
                                    </a>
                                </li>
                                <li v-if="unusedAlarmLevels.length" class="nav-item">
                                    <a class="nav-link" href="#">
                                        <div class="dropdown">
                                            <a href="#"
                                               @click.prevent
                                               class="text-decoration-none text-primary"
                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-plus"></i>
                                                <span class="font-weight-bold" v-if="!levels.length">
                                                    {{ $t('Добавить уровень') }}
                                                </span>
                                            </a>
                                            <div class="dropdown-menu app-dropdown">
                                                <a v-for="item in unusedAlarmLevels"
                                                   :key="item.level"
                                                   @click.prevent="addLevel(item)"
                                                   class="dropdown-item" href="#">
                                                    {{ item.levelName }}
                                                </a>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div v-if="currentLevelConditions && currentLevelConditions.length" class="col-12 mb-4 text-center">
                            <button @click="scheduleBox = false"
                                    :class="{'btn-purple' : !scheduleBox, 'btn-outline-purple' : scheduleBox}"
                                    class="btn btn-lg">
                                <i class="fas fa-fw fa-list-ul"></i>
                                {{ $t('Условия аварии') }}
                            </button>
                            <button @click="scheduleBox = true"
                                    :class="{'btn-purple' : scheduleBox, 'btn-outline-purple' : !scheduleBox}"
                                    class="btn btn-lg ml-5">
                                <i class="far fa-fw fa-clock"></i>
                                {{ $t('Расписание уведомлений') }}
                            </button>
                        </div>
                        <div class="col-12">
                            <AlarmTypeModalLevelSchedule v-if="scheduleBox"
                                                         :levelIndex="currentLevelIndex"
                                                         :levelData="currentLevel"
                                                         @change="changeLevelSchedule"/>
                            <template v-else>
                                <AlarmTypeCondition v-for="(item, index) in currentLevelConditions"
                                                    :key="index"
                                                    :value="currentLevelConditions[index]"
                                                    :index="index"
                                                    :deviceObject="deviceObject"
                                                    :conditions="currentLevelConditions"
                                                    @change="changeConditionItem"
                                                    @remove="removeConditionItem"/>

                                <div class="btn btn-primary btn-block"
                                     v-if="levels.length && deviceMetrics.length"
                                     @click="addConditionItem">
                                    <i class="fas fa-plus"></i>
                                    {{ $t('Добавить условие') }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="update"
                            :disabled="processing || !isValid"
                            style="min-width: 110px;"
                            class="btn btn-purple">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import _ from "underscore";
import $ from "jquery";
import config from "../../config";
import {mapState} from 'vuex';
import AlarmTypeCondition from "../AlarmTypeCondition";
import AlarmTypeService from "../../services/AlarmTypeService";
import AlarmRuleService from "../../services/AlarmRuleService";
import AlarmConditionService from "../../services/AlarmConditionService";
import DeviceObject from "../../objects/DeviceObject";
import AlarmTypeModalLevelSchedule from "./AlarmTypeModalLevelSchedule";

const AlarmType = new AlarmTypeService()
const AlarmRule = new AlarmRuleService()
const AlarmCondition = new AlarmConditionService()

export default {
    name: "AlarmTypeUpdateModal",
    components: {
        AlarmTypeCondition,
        AlarmTypeModalLevelSchedule
    },
    props: {
        opened: {
            type: Boolean,
            required: true
        },
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        alarmType: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            levelsOld: [],
            levels: [],
            currentLevelTab: null,
            processing: false,
            title: "",
            isInit: false,
            scheduleBox: false
        }
    },
    methods: {
        removeLevel(level) {
            if (this.currentLevelConditions.length === 0 || confirm(this.$t('Удалить уровень? Так же будут удалены все условия этого уровня'))) {
                this.levels = _.reject(this.levels, item => item.level === level.level);
                this.currentLevelTab = this.levels[0]?.level;
            }
        },
        addLevel(item) {
            this.levels.push({
                conditions: [],
                schedule: ['* * * * *'],
                ...item
            });
            this.currentLevelTab = item.level;
        },
        getLevelColor(level) {
            return config.alarmLevelColors[level];
        },
        removeConditionItem(index) {
            this.levels[this.currentLevelIndex].conditions = _.reject(this.levels[this.currentLevelIndex].conditions, (item, idx) => idx === index);
        },
        changeConditionItem({index, item}) {
            this.levels[this.currentLevelIndex].conditions[index] = item
        },
        addConditionItem() {
            this.levels[this.currentLevelIndex].conditions.push({
                type_metric: null,
                metric_name: "",
                port: null,
                type: "eq",
                value: ""
            });
        },
        countLevelConditions(level) {
            return _.find(this.levels, item => item.level === level)?.conditions?.length || 0;
        },
        changeLevelSchedule({levelIndex, cronString}) {
            this.levels[levelIndex].schedule = [cronString];
        },
        async update() {

            this.processing = true;

            if (this.title !== this.alarmType?.title) {
                await AlarmType.update({
                    id: this.alarmType.id,
                    title: this.title,
                    enabled: this.alarmType.enabled
                });
            }

            // remove
            if (this.removingAlarmRules?.length) {
                for (let i in this.removingAlarmRules) {
                    await AlarmRule.remove(this.removingAlarmRules[i].id)
                }
            }

            // create
            if (this.creatingAlarmRules?.length) {
                for (let i in this.creatingAlarmRules) {
                    await AlarmRule.create(this.creatingAlarmRules[i])
                }
            }

            // update
            if (this.updatingAlarmRules?.length) {
                for (let i in this.updatingAlarmRules) {
                    await AlarmRule.update(this.updatingAlarmRules[i])
                }
            }

            // remove AlarmConditions
            if (this.removingAlarmConditions?.length) {
                for (let i in this.removingAlarmConditions) {
                    await AlarmCondition.remove(this.removingAlarmConditions[i]);
                }
            }

            // create AlarmConditions
            if (this.creatingAlarmConditions?.length) {
                for (let i in this.creatingAlarmConditions) {
                    await AlarmCondition.create(this.creatingAlarmConditions[i]);
                }
            }

            // update AlarmConditions
            if (this.updatingAlarmConditions?.length) {
                for (let i in this.updatingAlarmConditions) {
                    await AlarmCondition.update(this.updatingAlarmConditions[i]);
                }
            }

            const alarmType = await AlarmType.get(this.alarmType.id)

            this.$emit("updated", alarmType)
            this.$toast.success(this.$t("Настройки обновлены"));

            this.processing = false;
        }
    },
    computed: {
        ...mapState({
            types: state => state.types,
        }),
        // то что есть в levels но нет в levelsOld
        creatingAlarmRules() {
            const levelsOld = this.levelsOld?.map(item => item.level);

            return this.levels
                ?.filter(item => levelsOld.indexOf(item.level) === -1)
                ?.map(item => {
                    item.alarm_type = this.alarmType["@id"];
                    return item;
                })
        },
        // то что есть в levelsOld но нет в levels
        removingAlarmRules() {
            return this.levelsOld?.filter(item => {
                const levels = this.levels?.map(item => item.level);
                return levels?.indexOf(item.level) === -1;
            })
        },
        // schedule в levels отличные от levelsOld
        updatingAlarmRules() {
            const updating = [];

            this.levels?.forEach(level => {
                const oldLevel = this.levelsOld
                    .find(item => item.alarm_rule === level.alarm_rule);

                if (oldLevel && !_.isEqual(oldLevel?.schedule, level?.schedule)) {
                    updating.push(level);
                }
            });

            return updating;
        },
        // то что есть в levels но нет в levelsOld и уже создано (есть) в creatingAlarmRules
        creatingAlarmConditions() {
            const out = [];
            _.each(this.levels, level => {
                if (typeof level?.alarm_rule !== "undefined") {
                    _.each(level.conditions, condition => {
                        if (typeof condition['@id'] === "undefined") {
                            out.push({
                                alarm_rule: level.alarm_rule,
                                ...condition
                            })
                        }
                    })
                }
            })

            return out;
        },
        // то что есть в levelsOld но нет в levels и уже удалено (есть) в removingAlarmRules
        removingAlarmConditions() {
            const removingConditionsIds = _.chain(this.removingAlarmRules).pluck("conditions").flatten().pluck("@id").value();
            const oldConditionsIds = _.chain(this.levelsOld).pluck("conditions").flatten().pluck("@id").value();
            const newConditionsIds = _.chain(this.levels).pluck("conditions").flatten().pluck("@id").value();

            return _.filter(oldConditionsIds, item => {
                return newConditionsIds.indexOf(item) === -1 && removingConditionsIds.indexOf(item) === -1
            }).map(item => {
                return item.split("/").reverse()[0] * 1;
            })
        },
        // то что есть в levels и в levelsOld, но чего нет removingAlarmRules и creatingAlarmRules, но отличается
        updatingAlarmConditions() {
            const oldConditions = _.chain(this.levelsOld)
                .pluck("conditions")
                .flatten()
                .map(item => {
                    return [item["@id"], item]
                })
                .object()
                .value();

            return _.chain(this.levels)
                .pluck("conditions")
                .flatten()
                .filter(item => {
                    let oldCondition = oldConditions[item["@id"]];

                    return typeof item["@id"] !== "undefined"
                        && (oldCondition.metric_name !== item.metric_name
                            || oldCondition.value !== item.value
                            || oldCondition.type !== item.type
                            || oldCondition.port !== item.port)
                })
                .map(item => {
                    item.id = item['@id'].split("/").reverse()[0];
                    return item;
                })
                .value();
        },
        deviceMetrics() {
            return this.deviceObject.getType().getMetrics().map(item => item.getSlug());
        },
        alarmLevels() {
            return _.map(config.alarmLevels, (item, index) => {
                return {
                    level: index * 1,
                    levelName: item
                }
            });
        },
        unusedAlarmLevels() {
            return _.filter(this.alarmLevels, item => {
                return _.pluck(this.levels, "level").indexOf(item.level) === -1;
            })
        },
        currentLevel() {
            return this.levels[this.currentLevelIndex];
        },
        currentLevelConditions() {
            return this.currentLevel?.conditions;
        },
        currentLevelIndex() {
            return _.findIndex(this.levels, item => item.level === this.currentLevelTab);
        },
        isValid() {
            const notEmptyLevels = _.every(this.levels, item => item.conditions.length > 0)
            const allConditions = _.chain(this.levels)
                .pluck("conditions")
                .flatten()
                .value()

            const isFilledConditions = _.every(allConditions, condition => {
                return condition.metric_name && condition.value !== "" && condition.type;
            });

            return this.title && allConditions.length && isFilledConditions && notEmptyLevels;
        }
    },
    watch: {
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");

                const alarmType = JSON.parse(JSON.stringify(this.alarmType));

                this.title = alarmType.title;
                this.levels = alarmType.create_rules.map(item => {
                    return {
                        id: item["@id"].split("/").reverse()[0],
                        alarm_rule: item["@id"],
                        level: item.level,
                        levelName: config.alarmLevels[item.level],
                        conditions: item.conditions,
                        schedule: item.schedule,
                    }
                })

                this.levelsOld = JSON.parse(JSON.stringify(this.levels));
                this.currentLevelTab = this.levels[0]?.level;

            } else {
                Object.assign(this.$data, this.$options.data());
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
        currentLevelTab() {
            this.scheduleBox = false;
        }
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style lang="less" scoped>
    .modal-body {
        .btn {
            font-size: 14px;
        }
    }
</style>
