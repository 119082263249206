<template>
    <vue-good-table
        :columns="columns"
        :rows="accidents"
        :pagination-options="vgtPaginationOptions()"
        :row-style-class="rowStyleClassFn">
        <template slot="table-row" slot-scope="props">

            <a v-if="props.column.field === 'device'"
               @click.prevent="toDevice(props.row.device)"
               href="#">
                <small class="font-weight-bold">
                    {{ getDeviceObject(props.row.device).getName() | stringLimit }}
                </small>
            </a>

            <a v-else-if="props.column.field === 'name'"
               @click.prevent="toAlarm(props.row)"
               href="#">
                <small class="font-weight-bold">
                    {{ props.row.alarm.title | stringLimit }}
                </small>
            </a>

            <small v-else-if="props.column.field === 'level'"
                   :style="{ color: getAlarmLevelColor(props.row.level)}"
                   class="font-weight-bold">
                {{ getAlarmLevelName(props.row.level) }}
            </small>

            <small v-else-if="['created_at', 'updated_at', 'disabled_at'].indexOf(props.column.field) > -1">
                {{ dateFormat(props.formattedRow[props.column.field]) }}
            </small>

            <button v-else-if="props.column.field === 'action'"
                    @click="selectAccident(props.row)"
                    class="btn btn-sm btn-purple btn-block">
                {{ $t('Обзор') }}
            </button>

            <div v-else>
                {{ props.formattedRow[props.column.field] }}
            </div>

        </template>
    </vue-good-table>
</template>

<script>

import config from "../config";
import moment from "moment-mini";
import DeviceObject from "../objects/DeviceObject";
import {mapState} from "vuex";
import Helper from "../classess/Helper";

export default {
    name: "AccidentsTable",
    props: {
        accidents: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            currentAccidentId: null,
            columns: [
                {
                    label: this.$t('Устройство'),
                    field: 'device',
                    sortable: false,
                },
                {
                    label: this.$t('Авария'),
                    field: 'name',
                    sortable: false,
                },
                {
                    label: this.$t('Уровень'),
                    field: 'level',
                },
                {
                    label: this.$t('Создано'),
                    field: 'created_at',
                    sortable: false
                },
                {
                    label: this.$t('Обновлено'),
                    field: 'updated_at',
                    sortable: false,
                },
                {
                    label: this.$t('Завершено'),
                    field: 'disabled_at',
                    sortable: false,
                },
                {
                    label: this.$t('Действия'),
                    field: 'action',
                    sortable: false,
                },
            ],
        }
    },
    filters: {
        stringLimit: string => Helper.stringLimit(string || "", 50)
    },
    methods: {
        vgtPaginationOptions: config.vgtPaginationOptions,
        rowStyleClassFn(row) {
            return row['@id'].split("/").reverse()[0] === this.currentAccidentId ? 'current-accident-row' : '';
        },
        dateFormat(date) {
            return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "-"
        },
        getAlarmLevelColor(level) {
            return config.alarmLevelColors[level];
        },
        getAlarmLevelName(level) {
            return config.alarmLevels[level];
        },
        getDeviceObject(deviceIRI) {
            const device = this.devices.find(item => item.id === deviceIRI.split("/").reverse()[0]);
            return new DeviceObject(device);
        },
        toAlarm({device, alarm_type}) {
            this.$router.push({
                name: 'DeviceAlarm',
                params: {
                    id: this.getDeviceObject(device).getId()
                },
                query: {
                    highlight_alarm_id: alarm_type.split('/').reverse()[0]
                }
            });
        },
        toDevice(device) {
            this.$router.push({
                name: 'Devices',
                query: {
                    highlight_device_id: this.getDeviceObject(device).getId()
                }
            });
        },
        selectAccident(accident) {
            this.currentAccidentId = accident['@id'].split("/").reverse()[0];
            this.$emit('select', accident)
        }
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        })
    },
}
</script>

<style>
.current-accident-row {
    background: #e2cbf4;
}
</style>
