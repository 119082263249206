export default class MetricObject {

    constructor(metric) {
        this.metric = metric;
        this.portIndex = null;
    }

    clone() {
        return new MetricObject(this.metric);
    }

    getId() {
        return this.metric['@id'];
    }

    getSlug() {
        return this.metric?.slug;
    }

    getIcon() {
        return this.metric?.icon;
    }

    getColor() {
        return this.metric?.color;
    }

    getName() {
        return this.metric?.name //;
    }

    getPrefix() {
        return this.metric?.units_prefix;
    }

    getPostfix() {
        return this.metric?.units_postfix;
    }

    getUnits(def = "") {
        return this.getPostfix() || this.getPrefix() || def;
    }

    getCountPorts() {
        return this.metric?.ports;
    }

    setPortIndex(index) {
        this.portIndex = index;
        return this;
    }

    getPortIndex() {
        return this.portIndex;
    }

}
