import AbstractService from "./AbstractService";

export default class DeviceAccessService extends AbstractService {

    constructor() {
        super();
    }

    async get(accessId) {
        const {code, body} = await this.request.get(this.config.apiUrl + '/v1/device_accesses/' + accessId);
        return code === 200 ? body : null;
    }

    async fetch() {
        const {code, body} = await this.request.get(this.config.apiUrl + `/v1/device_accesses`);
        return code === 200 ? body["hydra:member"] : [];
    }

    async fetchByDevice(deviceId) {
        const {code, body} = await this.request.get(this.config.apiUrl + `/v1/devices/${deviceId}/accesses`);
        return code === 200 ? body["hydra:member"] : [];
    }

    async create(access) {
        return await this.request.post(this.config.apiUrl + "/v1/device_accesses", access);
    }

    async update(access) {
        const {code, body} = await this.request.put(this.config.apiUrl + "/v1/device_accesses/" + access.id, access);
        return code === 200 ? body : false;
    }

    async remove(accessId) {
        const {code} = await this.request.delete(this.config.apiUrl + "/v1/device_accesses/" + accessId);
        return code === 204;
    }
}