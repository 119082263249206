<template>
    <div class="btn-group btn-block h-100" v-if="groups.length">
        <button class="btn btn-outline-purple"
                :class="{ active : 'all' === currentCaseName }"
                @click="currentCaseName = 'all'">
            <span class="d-none d-xl-inline"> {{ $t('Все устройства') }}</span>
        </button>
        <button v-for="(item, index) in groups"
                :key="index"
                :class="{ active : item.name === currentCaseName }"
                @click="currentCaseName = item.name"
                class="btn btn-outline-purple"
                data-toggle="tooltip"
                :title="item.name">
            <span>{{ stringLimit(item.name, 10) }}</span>
        </button>
    </div>
    <div class="empty-cases" v-else>
        {{ $t('Вы ещё не создали группу') }}
    </div>
</template>

<script>

    import $ from "jquery";
    import {mapState} from "vuex";
    import Icon from "./Icon";
    import Helper from "../classess/Helper";

    export default {
        name: "GroupTabs",
        components: {
            Icon
        },
        data() {
            return {
                currentCaseName: "all",
            }
        },
        methods: {
            stringLimit: Helper.stringLimit
        },
        computed: {
            ...mapState({
                currentObject: state => state.currentObject,
                groups: state => state.groups,
            }),
        },
        watch: {
            case(val) {
                this.groups = val
            },
            currentCaseName(val) {
                $('[data-toggle="tooltip"]').tooltip("hide");
                this.$emit("change", this.groups.find(item => item.name === val));
            }
        },
        mounted() {

        }

    }
</script>

<style scoped lang="less">
    @import "../less/_variables";

    button {
        background-color: white;
    }

    .empty-cases {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed @btn-bg;
        color: @btn-bg;
        border-radius: 8px;
        font-weight: 500;
        height: 100%;
        background: white;
    }
</style>
