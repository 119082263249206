<template>
    <div class="position-fixed settings-item">
        <div class="settings-item-header p-4">
            <div class="row">
                <div class="col-10 text-center font-weight-bold">
                    <h5>{{ $t('Редактирование групп') }}</h5>
                </div>
                <div class="col-2">
                    <button type="button" class="close" @click="$emit('closed')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="settings-item-body p-4">
            <select v-model="selectedGroupId" class="form-control mb-4" @change="selectGroup()">
                <option :value="''"  disabled>
                    {{ $t('Выберите группу') }}
                </option>
                <option v-for="group in groups"
                        :key="group.id"
                        :value="group['@id']">
                    {{ group.name }}
                </option>
            </select>
            <div v-if="selectedGroupId">
                <input v-model="groupName" type="text" class="form-control mb-4" :placeholder="selectedGroup.name">
                <div class="card pt-3 px-2 mb-4 bg-gray border-0">
                    <div v-for="item in devices" class="rounded d-flex align-items-center justify-content-between bg-white pl-3 mb-3">
                        <div>
                            {{ item.name | stringLimit }}
                        </div>
                        <div>
                            <toggle-button
                                :sync="true"
                                class="rounded-right"
                                :color="toggleColor"
                                :value="item.device_group === selectedGroupId"
                                :width="34"
                                :height="14"
                                :disabled="processing"
                                :switch-color="switchColor"
                                @change="(e) => onChangeToggleHandler(e, item)"/>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <button @click="remove()"
                            class="btn btn-danger"
                            :disabled="processing"
                            style="min-width: 45%">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Удалить') }}</span>
                    </button>
                    <button
                        @click="update()"
                        :disabled="processing || groupName === selectedGroup.name"
                        style="min-width: 45%"
                        class="btn btn-purple">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Переименовать') }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="backdrop" @click="$emit('closed')"></div>
    </div>
</template>
<script>

import Icon from "../Icon";
import {mapState} from "vuex";
import GroupService from "../../services/GroupService";
import DeviceService from "../../services/DeviceService";
import config from "../../config";
import Helper from "../../classess/Helper";
import _ from "underscore";

const Group = new GroupService();
const Device = new DeviceService();

export default {
    name: "EditGroups",
    components: {
        Icon
    },
    filters: {
        stringLimit: str => Helper.stringLimit(str, 30)
    },
    data: () => ({
        processing: false,
        selectedGroupId: '',
        selectedGroup: {},
        groupDevices: [],
        groupName: '',
    }),
    methods: {
        async onChangeToggleHandler(e, device) {
            if (e.value) {
                this.groupDevices.push(device);
            } else {
                this.groupDevices = _.reject(this.groupDevices, item => {
                    return item.id === device.id;
                });
            }
            this.processing = true;
            const result = await Device.update({
                ...device,
                device_group: e.value ? this.selectedGroupId : null
            })
            this.processing = false;
            if (result) {
                this.$toast.success(e.value ? this.$t('Устройство добавленно в группу') : this.$t('Устройство удаленно из группы'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
        selectGroup() {
            this.selectedGroup = this.groups.find(group => group['@id'] === this.selectedGroupId);
            this.findGroupDevices();
        },
        async update() {
            this.processing = true;
            const result = await Group.update({id: this.selectedGroup.id, name: this.groupName});
            this.processing = false;

            if (result) {
                this.$toast.success(this.$t('Группа переименованна'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
        async remove() {
            this.processing = true;
            const result = await Group.remove(this.selectedGroup.id);
            this.processing = false;

            if (result) {
                this.$toast.success(this.$t('Группа удалена'));
                this.selectedGroup = {};
                this.selectedGroupId = '';
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
        findGroupDevices() {
            this.groupDevices = this.devices.filter(device => device.device_group === this.selectedGroupId)
        }
    },
    computed: {
        ...mapState({
            currentTheme: state => state.currentTheme,
            devices: state => state.devices,
            currentObject: state => state.currentObject,
            groups: state => state.groups,
        }),
        toggleColor() {
            return config.toggleColor;
        },
        switchColor() {
            return config.switchColor;
        },
        isValid() {
            return this.selectedGroup?.length > 0
                && this.selectedGroup?.name
        },
    },
}
</script>

<style scoped lang="less">
    @import "../../less/_variables";

    .settings-item {
        width: 495px;
        background: white;
        height: 100%;
        z-index: 1001;
        margin-left: @settings-menu-width;
        box-shadow: 0 4px 4px rgba(51, 51, 51, 0.04), 0px 5px 24px rgba(51, 51, 51, 0.1);
        top: 0;

        &-header {
            border-bottom: 1px solid #D8D8D8;
        }
    }

</style>
