import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from "./i18n";
import "jquery";
import "popper.js";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import ToggleButton from 'vue-js-toggle-button';
import "bootstrap"
import EventBusPlugin from "./EventBus";
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import VueWaypoint from 'vue-waypoint'
import MasonryWall from '@yeger/vue2-masonry-wall'

Vue.config.productionTip = false;
Vue.use(VueToast, {
    position: "top-right"
});
Vue.use(VueGoodTablePlugin);
Vue.use(ToggleButton);
Vue.use(EventBusPlugin);
Vue.use(VueWaypoint)
Vue.use(MasonryWall)

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        Vue,
        dsn: "https://31f333d9f2384660bf65763923edd71b@o364517.ingest.sentry.io/6064241",
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [/^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
