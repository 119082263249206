<template>
    <Layout>
        <div class="row">
            <div class="col-12 mb-4">
                <h4 class="title">{{ $t('Статистика') }}</h4>
                <div class="text-muted">{{ $t("Устройство:") }} <span class="font-weight-bold">{{ deviceName }}</span></div>
                <div class="text-muted">DevEUI: <span class="font-weight-bold">{{ deviceDevEui }}</span></div>
                <hr class="mb-0">
            </div>

            <DevicePeriodPicker :deviceObject="deviceObject"
                                :deviceVisibleMetrics="deviceVisibleMetrics"
                                @change="period => this.selectedPeriod = period"/>

            <Abstract v-for="(item, index) in deviceVisibleMetrics"
                      :metricObject="item"
                      :key="index"
                      :deviceObject="deviceObject"
                      :aggregatedTimeseries="aggregatedTimeseries"
                      :timeseries="timeseries"
                      :selectedPeriod="selectedPeriod"/>
        </div>
    </Layout>
</template>

<script>

import DeviceTimeseriesService from "../services/DeviceTimeseriesService";
import DeviceService from "../services/DeviceService";
import types from "../store/types";
import Layout from "../components/Layout";
import DeviceObject from "../objects/DeviceObject";
import Abstract from "../components/deviceCharts/Abstract";
import TimeseriesObject from "../objects/TimeseriesObject";
import DevicePeriodPicker from "../components/DevicePeriodPicker";
import metricsConfig from "../metricsConfig";

const Timeseries = new DeviceTimeseriesService();
const Device = new DeviceService()

export default {
    name: 'Device',
    components: {
        Layout,
        Abstract,
        DevicePeriodPicker
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            customGroupBy: null,
            device: {},
            aggregatedTimeseries: [],
            timeseries: [],
            selectedPeriod: {}
        }
    },
    methods: {
        async loadTimeseries() {
            let timeseries = await Timeseries.get(
                this.id,
                this.selectedPeriod.period[0],
                this.selectedPeriod.period[1]
            );

            this.timeseries = timeseries.map(item => new TimeseriesObject(item));
        },
        async loadAggregatedTimeseries() {

            // в случае selectedPeriod.groupBy == null, будут загружены данные БЕЗ агрегации
            let timeseries = await Timeseries.get(
                this.id,
                this.selectedPeriod.period[0],
                this.selectedPeriod.period[1],
                this.selectedPeriod.groupBy
            );

            this.aggregatedTimeseries = timeseries.map(item => new TimeseriesObject(item));

        },
        async loadStat() {
            if (this.hasNotAggregatedMetrics) {
                await this.loadTimeseries();
                await this.loadAggregatedTimeseries();
            } else {
                await this.loadAggregatedTimeseries();
            }
        }
    },
    computed: {
        hasNotAggregatedMetrics() {
            let deviceTypeSlug = this.deviceObject.getType().getSlug();
            return metricsConfig[deviceTypeSlug]?.notAggregatedMetrics?.length;
        },
        deviceObject() {
            return new DeviceObject(this.device);
        },
        deviceName() {
            return this.deviceObject?.getName();
        },
        deviceDevEui() {
            return this.deviceObject?.getDevEui();
        },
        deviceVisibleMetrics() {
            let deviceTypeSlug = this.deviceObject.getType()?.getSlug()

            return this.deviceObject.getType().getMetricsWithPorts()
                ?.filter(item => {

                    if (!metricsConfig.hasOwnProperty(deviceTypeSlug)) {
                        return true;
                    }

                    if (metricsConfig[deviceTypeSlug].hasOwnProperty("chartMetricsBlacklist")
                        && Array.isArray(metricsConfig[deviceTypeSlug].chartMetricsBlacklist)
                    ) {
                        return metricsConfig[deviceTypeSlug].chartMetricsBlacklist.indexOf(item.getSlug()) === -1;
                    }

                    return true;
                })
                ?.sort((a, b) => {
                    return b.getSlug() === "bat_volt" ? -1 : 0;
                });
        },
        isReloadStat() {
            return this.deviceObject.getType().getSlug() && JSON.stringify(this.selectedPeriod);
        }
    },
    watch: {
        async isReloadStat() {
            await this.$nextTick();
            this.$store.commit(types.PRELOADER, true);
            await this.loadStat();
            this.$store.commit(types.PRELOADER, false);
        }
    },
    async mounted() {
        this.$store.commit(types.PRELOADER, true);
        this.device = await Device.get(this.id);
        setTimeout(() => {
            this.$store.commit(types.PRELOADER, false);
        }, 1000);
    }
}
</script>

<style lang="less">

.active-custom-breakpoint input {
    border: 1px solid #732AAD;
    border-radius: 5px;
}

</style>
