import types from "../types";
import StorageAdapter from "../../classess/StorageAdapter";

const storage = new StorageAdapter();

export const DevicesSettings = {
    state() {
        return {
            showMap: storage.get("showMap", true),
            deviceCardAccidentsHighlight: storage.get("deviceCardAccidentsHighlight", true),
        }
    },
    mutations: {
        [types.SET_SHOW_MAP](state, payload) {
            state.showMap = payload;
            storage.set("showMap", payload);
        },
        [types.DEVICE_CARD_ACCIDENTS_HIGHLIGHT](state, payload) {
            state.deviceCardAccidentsHighlight = payload;
            storage.set("deviceCardAccidentsHighlight", payload);
        },
    }
}
