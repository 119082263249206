<template>
    <div class="position-fixed settings-item">
        <div class="settings-item-header p-4">
            <div class="row">
                <div class="col-10 text-center font-weight-bold">
                    <h5>{{ $t('Добавить группу') }}</h5>
                </div>
                <div class="col-2">
                    <button type="button" class="close" @click="$emit('closed')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="settings-item-body p-4">
            <small class="d-block alert alert-success font-italic mb-4">
                {{ $t('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ') }}
            </small>
            <div>
                <input v-model="groupName" type="text" class="form-control mb-4" :placeholder="$t('Название новой группы')">
                <div class="card pt-3 px-2 mb-4 bg-gray border-0">
                    <div v-for="item in devices" class="rounded d-flex align-items-center justify-content-between bg-white pl-3 mb-3">
                        <div>
                            {{ item.name | stringLimit }}
                        </div>
                        <div>
                            <toggle-button
                                class="rounded-right"
                                :color="toggleColor"
                                :width="34"
                                :height="14"
                                :switch-color="switchColor"
                                @change="(e) => onChangeToggleHandler(e, item)"/>
                        </div>
                    </div>
                </div>
                <button @click="create"
                        :disabled="processing || !isValid"
                        style="width: 150px;"
                        class="btn btn-block btn-purple mx-auto cursor-pointer">
                    <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span v-else>{{ $t('Сохранить') }}</span>
                </button>
            </div>
        </div>
        <div class="backdrop" @click="$emit('closed')"></div>
    </div>
</template>

<script>

import Icon from "../Icon";
import {mapState} from "vuex";
import config from "../../config";
import _ from "underscore";
import Helper from "../../classess/Helper";
import $ from "jquery";
import GroupService from "../../services/GroupService";
import DeviceService from "../../services/DeviceService";

const Group = new GroupService();
const Device = new DeviceService();

export default {
    name: "AddGroups",
    components: {
        Icon
    },
    filters: {
        stringLimit: str => Helper.stringLimit(str, 30)
    },
    data: () => ({
        processing: false,
        groupName: '',
        groupDevices: []
    }),
    methods: {
        onChangeToggleHandler(e, device) {
            if (e.value) {
                this.groupDevices.push(device);
            } else {
                this.groupDevices = _.reject(this.groupDevices, item => {
                    return item.id === device.id;
                });
            }
        },
        async create() {
            this.processing = true;
            // создаём группу
            const currentGroup = await Group.create(this.currentObject.account_id, this.groupName);
            // проверяем по ответу от сервера наличия такой группы
            if(currentGroup === 422) {
                this.$toast.error(this.$t('Группа c таким именем уже создана'));
                this.processing = false;
                return
            }
            if(currentGroup) {
                // добовляем всем выбранным девайсам device_group
                this.groupDevices.forEach((device) => {
                    Device.update({
                        ...device,
                        device_group: `/v1/device_groups/${currentGroup.id}`
                    })
                });
                this.processing = false;
                this.groupDevices = [];
                this.groupName = '';
                this.$toast.success(this.$t('Группа создана'));
                this.$emit('closed');
            } else {
                this.$toast.error(this.$t('Ошибка'));
                this.processing = false;
            }
        },
    },
    computed: {
        ...mapState({
            currentTheme: state => state.currentTheme,
            devices: state => state.devices,
            currentObject: state => state.currentObject,
            groups: state => state.groups,
        }),
        toggleColor() {
            return config.toggleColor;
        },
        switchColor() {
            return config.switchColor;
        },
        isValid() {
            return this.groupDevices?.length > 0
                && this.groupName?.length > 0
        },
    },
}
</script>

<style scoped lang="less">
    @import "../../less/_variables";

    .settings-item {
        width: 495px;
        background: white;
        height: 100%;
        z-index: 1001;
        margin-left: @settings-menu-width;
        box-shadow: 0 4px 4px rgba(51, 51, 51, 0.04), 0px 5px 24px rgba(51, 51, 51, 0.1);
        top: 0;

        &-header {
            border-bottom: 1px solid #D8D8D8;
        }
    }

</style>
