<template>
    <div>
        <div class="settings-menu position-fixed"
             v-if="showSettingsMenu">
            <div class="d-flex flex-column align-items-center">
                <div class="settings-menu-item add mb-4 cursor-pointer" @click="setActiveMenu('addGroups')">
                    <Icon icon-name="settings-plus" stroke="#8B7FE8"/>
                    <div class="settings-menu-item_tooltip">{{ $t('Создать группу') }}</div>
                </div>
                <div class="settings-menu-item mb-4 cursor-pointer" @click="setActiveMenu('editGroups')">
                    <Icon icon-name="edit" width="25" height="25" fill="#FFF" stroke="#FFF"/>
                    <div class="settings-menu-item_tooltip">{{ $t('Редактировать группы') }}</div>
                </div>
                <div class="settings-menu-item mb-4 cursor-pointer" @click="setActiveMenu('settingsAPP')">
                    <Icon icon-name="settings-edit" width="25" height="25" stroke="#FFF" fill="#7666D8"/>
                    <div class="settings-menu-item_tooltip">{{ $t('Настройки приложения') }}</div>
                </div>
                <div class="settings-menu-item cursor-pointer">
                    <Icon icon-name="settings-sorting" fill="#7666D8" stroke="#FFF" width="25" height="25"/>
                    <div class="settings-menu-item_tooltip">{{ $t('Создать группу') }}</div>
                </div>
            </div>
            <div v-if="!activeMenu" class="backdrop" @click="hideSettings"></div>
        </div>
        <AddGroups v-if="activeMenu==='addGroups' && showSettingsMenu" @closed="activeMenu=''"/>
        <EditGroups v-if="activeMenu==='editGroups' && showSettingsMenu" @closed="activeMenu=''"/>
        <SettingsAPP v-if="activeMenu==='settingsAPP' && showSettingsMenu" @closed="activeMenu=''"/>
    </div>
</template>

<script>
import Icon from "../Icon";
import {mapState} from "vuex";
import types from "../../store/types";
import AddGroups from "./AddGroups";
import EditGroups from "./EditGroups";
import SettingsAPP from "./SettingsAPP";

export default {
    name: "SettingsMenu",
    components: {
        SettingsAPP,
        EditGroups,
        Icon,
        AddGroups
    },
    data: () => ({
        activeMenu: '',
    }),
    methods: {
        hideSettings() {
            this.$store.commit(types.TOGGLE_SETTINGS, false)
        },
        setDesign(value) {
            this.$store.commit(types.SET_CARD_DESIGN, value);
        },
        setActiveMenu(menu) {
            if(this.activeMenu === menu) {
                this.activeMenu = ''
            } else {
                this.activeMenu = menu
            }
        }
    },
    computed: {
        ...mapState({
            showSettingsMenu: state => state.showSettingsMenu,
            currentTheme: state => state.currentTheme,
        }),
    },
}
</script>

<style scoped lang="less">
    @import "../../less/_variables";

    .settings-menu {
        width: @settings-menu-width;
        background: @settings-menu-bg;
        z-index: 1001;
        height: 100%;
        padding-top: 45px;
        top: 0;

        &-item {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;
            position: relative;

            &_tooltip {
                position: absolute;
                background: @settings-menu-bg;
                left: @settings-menu-width;
                padding: 10px 20px;
                border-radius: @border-radius;
                color: white;
                font-weight: 500;
                min-width: 250px;
                text-align: center;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
                cursor: default;
                display: none;
                z-index: 9999;
            }

            &:hover {
                background: #FFFFFF50;

                .settings-menu-item_tooltip {
                    display: block;
                    opacity: 1;
                }
            }
        }

        .add {
            background: white;
        }
    }
</style>
