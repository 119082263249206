<template>
    <div>
        <div class="deviceCardMetricItem d-flex align-items-center justify-content-between w-100 font-weight-500"
             v-for="(metric, index) in deviceMetrics"
             style="height: 57px"
             :key="index">
            <div class="w-50 p-3">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="pr-2">
                        <div class="pr-1">
                            <span class="position-relative">
                            <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                                {{ metric.getPortIndex() + 1 }}
                            </span>

                            <Icon :icon-name="metric.getIcon()"
                                  width="25"
                                  height="25"
                                  :fill="isSelected(metric) ? metric.getColor() : '#AAB2BF'"/>
                            </span>
                        </div>
                    </div>
                    <div v-if="metric.getName().length > 37"
                         data-toggle="tooltip"
                         :title="metric.getName()">
                        {{ stringLimit(metric.getName(), 37) }}
                    </div>
                    <div v-else>
                        {{ metric.getName() }}
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center w-17 border-left h-100"
                 :class="{'bg-accident': metricHasAccident(metric)}">
                {{ getMetricLabel(metric) }}
            </div>
            <div class="w-33 px-3 pt-1 border-left h-100">
                <CardBarChart :width="100" :height="53" :metricTimeseries="getMetricTimeseries(metric)" :animationDuration="0" v-if="getMetricTimeseries(metric).length"/>
                <div class="d-flex align-items-center justify-content-center h-100" v-else>N/A</div>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import Helper from "../../../classess/Helper";
import DeviceObject from "../../../objects/DeviceObject";
import CardBarChart from "./CardBarChart";

export default {
    name: "DefaultCardMetricList",
    components: {
        CardBarChart,
        Icon,
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: false
        },
        accidents: {
            type: Array,
            required: false
        },
    },
    data() {
        return {
            metricWithAccident: []
        }
    },
    methods: {
        metricHasAccident(metric) {
            this.accidents?.forEach(accident => {
                accident.alarm.create_rules[0].conditions.forEach(rule => {
                    if(metric?.getSlug() === rule.metric_name) {
                        if(!this.metricWithAccident.includes(metric?.getSlug())) {
                            this.metricWithAccident.push(metric?.getSlug())
                        }
                    }
                })
            });
            return this.metricWithAccident.includes(metric?.getSlug())
        },
        getMetricLabel(metric, fixed = 2) {
            const values = this.timeseries?.filter(item => item.getSlug() === metric?.getSlug());

            const value = metric?.getPortIndex()
                ? values[metric?.getPortIndex()]
                : values[0];

            const result = value?.getLatestData()?.getValue();

            return (result || result === 0) ? result?.toFixed(fixed) + " " + metric.getUnits() : "N/A";
        },
        getMetricTimeseries (metric) {
            return  this.timeseries?.find(item => item?.getSlug() === metric?.getSlug())?.getData() || [] ;
        },
        isSelected(metric) {
            return this.currentMetric?.getSlug() === metric.getSlug()
                && this.currentMetric?.getPortIndex() === metric.getPortIndex()
        },
        stringLimit: Helper.stringLimit,
    },
    computed: {
        deviceMetrics() {
            return this.deviceObject?.getType()?.getMetricsWithPortsExclude(["bat_volt"])
        }
    },
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.deviceCardMetricItem {
    border-bottom: 1px solid #D8D8D8;

    &:last-child {
        border-bottom: none;
    }
    .bg-accident {
        background: #FF9C9C70;
    }
}

.metric-icon-index {
    user-select: none;
    position: absolute;
    top: -5px;
    right: -10px;
    padding: 1px 7px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
}

/*.list-group {*/
/*    border-radius: 0;*/

/*    &-item {*/
/*        display: flex;*/
/*        border-color: transparent;*/
/*        padding: 0.45rem 1.25rem;*/
/*        font-size: 0.9rem;*/
/*        cursor: pointer;*/
/*        border-radius: .5rem;*/

/*        &:hover {*/
/*            background: white;*/
/*        }*/

/*        &-title {*/
/*            font-weight: 500;*/
/*        }*/

/*        &-value {*/
/*            font-weight: 600;*/
/*            color: #706C6C;*/
/*        }*/

/*        &.active {*/
/*            color: #495057;*/
/*            background: #f1f1f1;*/
/*        }*/

/*        .status-icon {*/
/*            background: #FFFFFF;*/
/*            border-radius: 3px;*/
/*            width: 30px;*/
/*            height: 30px;*/
/*        }*/
/*    }*/
/*}*/



</style>
