import AbstractService from "./AbstractService";
import types from "../store/types";
export default class ObjectService extends AbstractService {

    constructor() {
        super();
    }

    async fetchAndStore() {
        const {code, body} = await this.request.get(this.config.apiUrl + "/v1/objects");

        if (code === 200) {
            await this.store.commit(types.OBJECTS, body["hydra:member"]);
            await this.store.commit(types.CURRENT_OBJECT, body["hydra:member"][0]);
            return true;
        }

        return false;
    }
}
