<template>
    <div class="container-fluid p-0 m-0 d-flex justify-content-between">
        <Sidebar/>
        <div class="app-content" ref="content">
            <SettingsMenu/>
            <Navbar/>
            <div class="container-fluid p-4">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import SettingsMenu from "./settingsMenu/SettingsMenu";

export default {
    name: "Layout",
    components: {
        SettingsMenu,
        Navbar,
        Sidebar
    },
}
</script>

<style lang="less" scoped>

@import "../less/_variables";

.app-content {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    overflow-y: auto;
    background: @content-bg;
    padding-top: @navbar-height;
}
</style>
