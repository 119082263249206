<template>
    <div ref="alarmCard" class="alarm-card app-card mr-lg-4 mb-4">
        <div class="alarm-card__head mb-4 text-right">
            <span class="font-weight-bold float-left">{{ stringLimit(alarmType.title, 30) }}</span>
            <Icon iconName="settings"
                  class="cursor-pointer"
                  data-toggle="dropdown"
                  width="25"
                  height="25"
                  fill="#64008D">
            </Icon>
            <div class="dropdown-menu app-dropdown">
                <a class="dropdown-item" href="#" @click.prevent="$emit('doUpdate', alarmType)">
                    <i class="fas fa-pen pr-4 text-purple fa-fw"></i>
                    {{ $t('Редактировать') }}
                </a>
                <a class="dropdown-item" href="#" @click.prevent="$router.push({name: 'Accidents', params: {deviceId : deviceObject.getId(), alarmId: alarmId }})">
                    <i class="fas fa-exclamation-circle pr-4 text-danger fa-fw"></i>
                    {{ $t('Обзор инцидентов') }}
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click.prevent="$emit('doRemove', alarmType)">
                    <i class="fas fa-trash-alt pr-4 text-danger fa-fw"></i>
                    {{ $t('Удалить') }}
                </a>
            </div>
        </div>
        <div class="alarm-card__body" :class="{disabled: !enabled}">
            <div class="rounded d-flex align-items-center justify-content-between bg-white pl-3 mb-3 border">
                <div>
                    {{ enabled ? $t('Отключить аварию') : $t('Включить аварию') }}
                </div>
                <div>
                    <toggle-button
                        class="rounded-right"
                        :color="toggleColor"
                        :width="34"
                        :height="14"
                        :sync="true"
                        :disabled="processing"
                        :value="enabled"
                        :switch-color="switchColor"
                        @change="(e) => onChangeToggleHandler(e)"/>
                </div>
            </div>
            <div class="btn-group mb-4">
                <button v-for="(item, index) in alarmLevels" :style="{color: item.levelColor}"
                        :key="index"
                        :class="{ active : index === currentLevelTab}"
                        @click="currentLevelTab = index"
                        :data-toggle="alarmLevels.length >= 4 ? 'tooltip' : ''"
                        :title="item.levelName"
                        class="btn btn-sm btn-light border">
                    <small class="font-weight-bold">{{ stringLimit(item.levelName, alarmLevels.length >= 4 ? 3 : 10) }}</small>
                </button>
            </div>
            <small class="mb-3 alert alert-warning text-muted font-italic">
                {{ $t('Авария состоится, если все нижеперечисленные условия будут выполнены') }}
            </small>
            <ul class="list-group">
                <li v-for="(condition, index) in alarmLevelConditions" :key="condition['@id']" class="list-group-item">
                    <AlarmTypeCardConditions :index="index"
                                             :condition="condition"
                                             :deviceObject="deviceObject"/>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import _ from "underscore";
import config from "../config";
import AlarmTypeCardConditions from "./AlarmTypeCardConditions";
import Helper from "../classess/Helper";
import Icon from "./Icon";
import DeviceObject from "../objects/DeviceObject";
import AlarmTypeService from "../services/AlarmTypeService";

const AlarmType = new AlarmTypeService();

export default {
    name: "AlarmTypeCard",
    components: {
        AlarmTypeCardConditions,
        Icon
    },
    props: {
        alarmType: Object,
        deviceObject: {
            type: DeviceObject,
            required: true
        },
    },
    data() {
        return {
            currentLevelTab: 0,
            enabled: this.alarmType?.enabled,
            processing: false
        }
    },
    methods: {
        stringLimit: Helper.stringLimit,
        highlightCard() {
            if (this.$route.query.highlight_alarm_id * 1 === this.alarmType.id) {
                this.$refs.alarmCard.classList.add("highlight_card");
                setTimeout(() => {
                    this.$refs.alarmCard.classList.remove("highlight_card");
                }, 1800)
            }
        },
        async onChangeToggleHandler(e) {
            this.processing = true;
           const result = await AlarmType.update({
                id: this.alarmType.id,
                title: this.alarmType.title,
                enabled: e.value
            });
           if(result) {
               this.enabled = e.value;
               e.value ? this.$toast.success(this.$t("Авария включена")) : this.$toast.success(this.$t("Авария выключена"));
           } else {
               this.$toast.success(this.$t("Ошибка"))
           }
            this.processing = false;
        }
    },
    computed: {
        toggleColor() {
            return config.toggleColor;
        },
        switchColor() {
            return config.switchColor;
        },
        alarmId() {
            return this.alarmType.id
        },
        alarmLevels() {
            return _.chain(this.alarmType.create_rules)
                .pluck("level")
                .map(item => {
                    return {
                        level: item,
                        levelName: config.alarmLevels[item],
                        levelColor: config.alarmLevelColors[item]
                    }
                }).value()
        },
        alarmLevelConditions() {
            return this.alarmType.create_rules[this.currentLevelTab]?.conditions || [];
        }
    },
    mounted() {
        this.highlightCard();
    }
}
</script>

<style lang="less" scoped>

.alarm-card {
    width: 100%;
    max-width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: .5rem;

    &__head {
        position: relative;

        .settings-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.btn {
    border-radius: 0.25rem;
}

.disabled {
    opacity: 0.6;
}

</style>
