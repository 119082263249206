import RequestWithAuth from "../classess/RequestWithAuth";
import config from "../config";
import store from "../store";

export default class AbstractService {

    constructor() {
        this.request = new RequestWithAuth();
        this.store = store;
        this.config = config;
    }

}