<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <DemoCaseTabs @change="changeCase"/>
            </div>
            <div class="col-12 mb-4" v-if="devices.length > 0 && cardsTimeseriesCache.length === devices.length && showMap">
                <DevicesMap :devices="devices"/>
            </div>
            <div class="col-12  mb-4" v-show="devices.length > 0">
                <div class="row">
                    <div class="col-6">
                        <GroupTabs @change="changeGroup"/>
                    </div>
                    <div class="col-6">
                        <input v-model="searchDevices" type="text" class="form-control input" :placeholder="$t('Поиск датчика по имени')">
                    </div>
                </div>
            </div>
            <div class="col-12" v-show="devices.length > 0">
                <div >
                    <div v-show="filteredDevices.length > 0">
                        <masonry-wall :items="filteredDevices" :gap="20" :column-width="setCardWidth()">
                            <template #default="{ item, index }">
                                <AbstractCard
                                    :deviceObject="item"
                                    @onLoad="onLoad"
                                    @deviceDoUpdate="deviceDoUpdate"
                                    @deviceDoShowGeoPosition="deviceDoShowGeoPosition"
                                    @deviceDoRemove="deviceDoRemove"
                                />
                            </template>
                        </masonry-wall>
                    </div>
                    <div v-show="filteredDevices.length === 0">
                        <div class="text-center">
                            <Icon icon-name="devices"
                                  class="my-5"
                                  fill="#6C757D"
                                  width="70"
                                  height="70"/>
                            <h4 class="text-muted text-center mb-4">{{ $t('По данному фильтру устройств не обнаруженно') }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <CenterBlock v-show="devices.length === 0">
                <div class="text-center">
                    <Icon icon-name="status"
                          class="mb-4"
                          fill="#6C757D"
                          width="70"
                          height="70"/>
                    <h4 class="text-muted text-center mb-4">{{ $t('Вы еще не добавляли устройства') }}</h4>
                    <button @click="deviceAddModal = true" class="btn btn-outline-purple">
                        <i class="fas fa-plus fa-fw"></i>
                        <span class="d-none d-md-inline pl-2">{{ $t("Добавить устройство") }}</span>
                    </button>
                </div>
            </CenterBlock>
        </div>
        <DeviceUpdateModal :opened="deviceUpdateModal"
                           :deviceObject="currentDeviceObject"
                           @closed="() => this.deviceUpdateModal = false"/>

        <DeviceGeoPositionModal :opened="deviceGeoPositionModal"
                                :deviceObject="currentDeviceObject"
                                @closed="() => this.deviceGeoPositionModal = false"/>

        <RemoveModal :opened="deviceRemoveModal"
                     :processing="removeProcessing"
                     :title="$t('Удалить устройство?')"
                     :text="$t('Вы уверены что хотите удалить устройство? Это действие нельзя будет отменить!')"
                     :buttonText="$t('Удалить')"
                     @removed="removeDevice"
                     @closed="() => this.deviceRemoveModal = false"/>
    </Layout>
</template>

<script>

import types from "../store/types";
import DevicesMap from "../components/DevicesMap"
import DeviceUpdateModal from "../components/modals/DeviceUpdateModal";
import DeviceGeoPositionModal from "../components/modals/DeviceGeoPositionModal";
import Icon from "../components/Icon";
import RemoveModal from "../components/modals/RemoveModal";
import DeviceService from "../services/DeviceService";
import Layout from "../components/Layout";
import AbstractCard from "../components/deviceCards/AbstractCard";
import DeviceObject from "../objects/DeviceObject";
import DemoCaseTabs from "../components/DemoCaseTabs";
import CenterBlock from "../components/CenterBlock";
import GroupTabs from "../components/GroupTabs";
import {mapState} from "vuex";

const Device = new DeviceService();

export default {
    name: 'Devices',
    components: {
        GroupTabs,
        Layout,
        Icon,
        DevicesMap,
        DeviceUpdateModal,
        DeviceGeoPositionModal,
        RemoveModal,
        AbstractCard,
        DemoCaseTabs,
        CenterBlock
    },
    data() {
        return {
            activeCase: null,
            activeGroup: null,
            deviceAddModal: false,
            deviceUpdateModal: false,
            deviceGeoPositionModal: false,
            deviceRemoveModal: false,
            removeProcessing: false,
            currentDeviceObject: new DeviceObject(),
            searchDevices: '',
        }
    },
    methods: {
        setCardWidth() {
            if(window.innerWidth < 1441) {
                return 565
            } else {
                return  650
            }
        },
        async removeDevice() {
            this.removeProcessing = true;
            const result = await Device.remove(this.currentDeviceObject.getId());
            this.removeProcessing = false;
            this.deviceRemoveModal = false;
            this.currentDeviceObject = {};

            if (result) {
                this.$toast.success(this.$t('Устройство удалено'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
        deviceDoUpdate(deviceObject) {
            this.currentDeviceObject = deviceObject;
            this.deviceUpdateModal = true;
        },
        deviceDoShowGeoPosition(deviceObject) {
            this.currentDeviceObject = deviceObject;
            this.deviceGeoPositionModal = true;
        },
        deviceDoRemove(deviceObject) {
            this.currentDeviceObject = deviceObject;
            this.deviceRemoveModal = true;
        },
        isDemoCaseDevice(device) {
            if (!this.activeCase) {
                return true;
            }

            return this.activeCase.types.indexOf(device.getDevEui()) > -1
                || this.activeCase.types.indexOf(device.getType().getSlug()) > -1
        },
        isCurrentGroupDevice(device) {
            if (!this.activeGroup) {
                return true;
            }
            return  this.activeGroup['@id'].indexOf(device.getGroup()) > -1
        },
        onLoad() {
            setTimeout(() => {
                this.$store.commit(types.PRELOADER, false);
            }, 500);
        },
        changeCase(data) {
            this.activeCase = data;
        },
        changeGroup(data) {
            this.activeGroup = data;
        },
    },
    computed: {
        ...mapState({
            groups: state => state.groups,
            cardsTimeseriesCache: state => state.cardsTimeseriesCache,
            showMap: state => state.DevicesSettings.showMap,
        }),
        devices() {
            return this.$store.state.devices
                ?.map(item => new DeviceObject(item))
                ?.filter(item => this.isDemoCaseDevice(item));
        },
        filteredDevices() {
            if(this.searchDevices) {
                return this.devices
                    ?.filter(item => this.isCurrentGroupDevice(item))
                    ?.filter(item => item.getName().toLowerCase().includes(this.searchDevices.toLowerCase()))
            } else {
                return this.devices
                    ?.filter(item => this.isCurrentGroupDevice(item))
            }


        }
    },
    mounted() {
        setTimeout(() => {
            this.$store.commit(types.PRELOADER, false);
        }, 3000);
    }
}
</script>
<style lang="less">
    .input {
        background: url("../assets/icons/search.svg") no-repeat 10px 44%, white;
        padding-left: 40px!important;
    }

</style>
