<script>

import moment from "moment-mini";
import DefaultTable from "./DefaultTable";

export default {
    name: "ValveStatusTable",
    extends: DefaultTable,
    data() {
        return {
            hiddenColumns: ["units"]
        }
    },
    computed: {
        rows() {
            const res = this.timeseries?.find(item => item.getSlug() === 'res')?.getData();

            return res.map(item => {

                let value;
                if (item.getValue() === 0) {
                    value = this.$t('Закрыто');
                } else if (item.getValue() === 1) {
                    value = this.$t('Открыто');
                } else {
                    value = "N/A";
                }

                return {
                    date: moment(item.getDate()).format("YYYY-MM-DD"),
                    time: moment(item.getDate()).format("HH:mm:ss"),
                    value: value,
                };
            })
        }
    }
}
</script>
