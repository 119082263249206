<template>
    <div>
        <HeaderComponent @deviceDoUpdate="$emit('deviceDoUpdate', $event)"
                          @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', $event)"
                          @deviceDoRemove="$emit('deviceDoRemove', $event)"
                          :deviceObject="deviceObject"
                          :accidents="accidents"
                          :timeseries="timeseries"/>
        <MetricList :deviceObject="deviceObject" :timeseries="timeseries" :accidents="accidents"/>
    </div>
</template>

<script>


import DeviceObject from "../../../objects/DeviceObject";
import DefaultCardHeader from "./DefaultCardHeader";
import DefaultCardMetricList from "./DefaultCardMetricList";


export default {
    name: "DefaultCard",
    components: {
        HeaderComponent: DefaultCardHeader,
        MetricList: DefaultCardMetricList,
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: false
        },
        accidents: {
            type: Array,
            required: false
        },
    },
}
</script>
