<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div ref="modalDialog" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Доступ к устройству') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened" class="modal-body">
                    <small class="d-block alert alert-success font-italic">
                        {{ $t('Вы можете открыть доступ к устройству другим пользователям. Укажите ID аккаунта, и выберите метрики которыми вы хотите поделится') }}
                    </small>
                    <div v-if="showDevicesList" class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Устройство') }}
                            <strong class="text-danger">*</strong></label>
                        <select @change="onChangeDevice" class="form-control">
                            <option v-for="(item, index) in devices" :key="index" :value="index">
                                {{ item.name + " - " + item.id }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Аккаунт ID') }}
                            <strong class="text-danger">*</strong></label>
                        <input v-model="access.account_id" type="number" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted d-block">
                            {{ $t('На сколько открываем доступ?') }} <strong class="text-danger">*</strong>
                        </label>
                        <date-picker v-model="accessPeriod"
                                     type="date"
                                     class="w-100"
                                     range
                                     :disabled-date="isDisabledDate"
                                     :lang="$store.state.appLocale"
                                     input-class="form-control"
                                     :placeholder="$t('Выбрать диапазон дат')">
                        </date-picker>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted d-block">
                            {{ $t('К каким метрикам даем доступ?') }} <strong class="text-danger">*</strong>
                        </label>
                        <div class="card pt-3 px-2 bg-gray mb-2 border-0">
                            <div v-for="item in deviceMetrics" class="rounded d-flex align-items-center justify-content-between bg-white pl-3 mb-3">
                                <div>
                                    <Icon :iconName="item.key" :fill="item.color" height="24" width="24"/>
                                    <span class="ml-3">{{ item.name | stringLimit }}</span>
                                    <span class="text-muted" v-if="item.port"> PORT: {{ item.port }}</span>
                                </div>
                                <div>
                                    <toggle-button
                                        class="rounded-right"
                                        :color="toggleColor"
                                        :width="34"
                                        :height="14"
                                        :switch-color="switchColor"
                                        @change="(e) => onChangeToggleHandler(e, item)"/>
                                </div>
                            </div>
                        </div>
                        <small class="text-muted font-italic">
                            {{ $t('Необходимо выбрать минимум одну метрику') }}
                        </small>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="create"
                            :disabled="processing || !isValid"
                            style="min-width: 110px;"
                            class="btn btn-purple">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment-mini";
import _ from "underscore";
import $ from "jquery";
import {mapState} from 'vuex';
import DatePicker from "vue2-datepicker";
import DeviceAccessService from "../../services/DeviceAccessService";
import Helper from "../../classess/Helper";
import config from "../../config";
import DeviceObject from "../../objects/DeviceObject";
import Icon from "../Icon";

const DeviceAccess = new DeviceAccessService();

export default {
    name: "DeviceAccessAddModal",
    components: {
        Icon,
        DatePicker,
    },
    props: {
        opened: Boolean,
        deviceId: {
            type: String,
            required: false,
            default: null
        },
        showDevicesList: {
            type: Boolean,
            required: false
        }
    },
    filters: {
        stringLimit: str => Helper.stringLimit(str, 30)
    },
    data() {
        return {
            processing: false,
            accessPeriod: [],
            accessMetrics: [],
            selectedDeviceIndex: 0,
            access: {
                device: null,
                account_id: null,
                start: null,
                stop: null,
                metrics: []
            }
        }
    },
    methods: {
        onChangeDevice(e) {
            this.selectedDeviceIndex = e.target.value * 1;
            this.access.device = this.devices[this.selectedDeviceIndex]['@id'];
        },
        onChangeToggleHandler(e, metric) {
            if (e.value) {
                this.accessMetrics.push(metric);
            } else {
                this.accessMetrics = _.reject(this.accessMetrics, item => {
                    return item.key === metric.key && item.port === metric.port;
                });
            }
        },
        isDisabledDate(date) {
            return moment(date).isBefore(moment().subtract(1, "day"));
        },
        async create() {
            this.processing = true;
            const {code, body} = await DeviceAccess.create({
                ...this.access,
                account_id: this.access.account_id * 1
            });
            this.processing = false;

            if (code === 201) {
                this.$toast.success(this.$t('Доступ к статистике устройства открыт!'));
                this.$emit('created', body);
                Object.assign(this.$data, this.$options.data());
                $(this.$refs.modal).modal("hide");
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        }
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        }),
        toggleColor() {
            return config.toggleColor;
        },
        switchColor() {
            return config.switchColor;
        },
        currentDevice() {
            if (this.deviceId) {
                return _.find(this.devices, item => item.id === this.deviceId);
            } else {
                return _.find(this.devices, (item, index) => index === this.selectedDeviceIndex);
            }
        },
        deviceObject(){
            return new DeviceObject(this.currentDevice);
        },
        deviceMetrics() {
            return this.deviceObject.getType().getMetrics().map(item => {
                return {
                    key: item.getSlug(),
                    name: item.getName(),
                    color: item.getColor(),
                    port: null
                }
            });
        },
        isValid() {
            return this.access.device?.length > 0
                && this.access.account_id?.length > 0
                && this.accessPeriod?.length === 2
                && this.access.metrics?.length > 0
        },
    },
    watch: {
        accessPeriod(val) {
            this.access.start = moment(val[0]).startOf("day").format();
            this.access.stop = moment(val[1]).endOf("day").format();
        },
        accessMetrics(val) {
            this.access.metrics = _.map(val, item => {
                return {
                    name: item.key,
                    port: null
                }
            });
        },
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");

                if (this.deviceId) {
                    this.access.device = this.deviceObject.getIRI();
                } else {
                    this.access.device = this.devices[this.selectedDeviceIndex]['@id'];
                }

            } else {
                Object.assign(this.$data, this.$options.data());
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style scoped>

</style>
