<script>
import {Bar} from 'vue-chartjs'
import moment from "moment-mini";

export default {
    extends: Bar,
    props: {
        metricTimeseries: {
            type: Array,
            required: true
        },
        animationDuration: {
            type: Number,
            required: false,
            default: 1000
        }
    },
    computed: {
        slicedTimeseries() {
            return this.metricTimeseries?.slice(0, 10).reverse();
        },
        hasNotTodayDate() {
            for (let item of this.slicedTimeseries) {
                if (!moment(item?.getDate()).isSame(new Date(), "day")) {
                    return true;
                }
            }
            return false;
        },
        chartData() {
            const parseLabel = (item) => {
                const format = this.hasNotTodayDate ? "MM.DD HH:mm" : "HH:mm";
                return moment(item?.getDate()).format(format);
            }
            return {
                labels: this.slicedTimeseries?.map(item => parseLabel(item)) || [],
                datasets: [
                    {
                        backgroundColor: '#D7D5E8',
                        hoverBackgroundColor: '#D7D5E8',
                        data: this.slicedTimeseries?.map(item => item?.getValue()) || [],
                        fill: false,
                        barPercentage: 1,
                        categoryPercentage: 0.9,
                    }
                ]
            }
        },
        // minMaxValues() {
        //     const percent = 20;
        //     const values = this.metricTimeseries?.map(item => item.getValue());
        //
        //     if (!values.length) {
        //         return {
        //             max: undefined,
        //             min: undefined,
        //         }
        //     }
        //
        //     const min = Math.min(...values);
        //     const max = Math.max(...values);
        //     const delta = max - min;
        //
        //     // return {
        //     //     max: Math.ceil((max * percent / 100) + max),
        //     //     min: Math.floor(min - (min * percent / 100)),
        //     // }
        //
        //     return {
        //         max: Math.ceil(max + delta),
        //         min: Math.floor(min - delta)
        //     }
        // },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },

                scales: { //hides the y axis
                    yAxes: [{
                        display: false
                    }],
                    xAxes: [{
                        display: false
                    }]
                },
                animation: {
                    duration: this.animationDuration
                },
            }
        }
    },
    watch: {
        metricTimeseries() {
            this.$nextTick(() => {
                this.renderChart(this.chartData, this.chartOptions);
            });
        },
        chartOptions() {
            this.$nextTick(() => {
                this.renderChart(this.chartData, this.chartOptions);
            });
        },
    },
    mounted() {
        this.renderChart(this.chartData, this.chartOptions);
    }
}
</script>

<style scoped>

</style>
