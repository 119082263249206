<template>
    <div class="app-navbar d-flex justify-content-between align-items-center px-3">
        <div>
            <button @click="collapseSidebar" class="btn btn-light">
                <i class="fas fa-bars"></i>
            </button>
            <button v-if="$route.name !== 'Home'" @click="$router.back()" class="btn btn-light ml-2 d-lg-none">
                <i class="fas fa-arrow-left"></i>
            </button>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <button v-if="hasUserdata"
                    @click="$router.currentRoute.name !== 'Profile' && $router.push({name: 'Profile'})"
                    class="btn btn btn-light mr-3"
                    data-toggle="tooltip"
                    data-placement="left"
                    :title=userFullName>
                <Icon icon-name="login" :fild="currentTheme" :stroke="currentTheme"></Icon>
            </button>
            <button @click="deviceAddModal = true" class="btn btn-light mr-3">
                <i class="fas fa-plus fa-fw"></i>
            </button>
            <button @click="logout" class="btn btn-light mr-3 logout">
                <i class="fas fa-sign-out-alt"></i>
                <span class="d-none d-md-inline"> {{ $t('Выйти') }}</span>
            </button>
        </div>
        <DeviceAddModal :opened="deviceAddModal"
                        @closed="() => this.deviceAddModal = false"/>
    </div>
</template>

<script>

import types from "../store/types";
import AuthService from "../services/AuthService";
import {mapState} from "vuex";
import Icon from "./Icon";
import DeviceAddModal from "../components/modals/DeviceAddModal";

const Auth = new AuthService();

export default {
    name: "Navbar",
    components: {Icon, DeviceAddModal},
    data() {
        return {
            deviceAddModal: false,
        }
    },
    methods: {
        collapseSidebar() {
            this.$store.commit(types.SIDEBAR_COLLAPSED, !this.$store.state.sidebarCollapsed);
        },
        logout() {
            Auth.logout();
            this.$router.push({name: "Auth"});
        },
    },
    computed: {
        ...mapState({
            userFullName: state => state.currentUser?.first_name + " " + state.currentUser?.last_name,
            accountId: state => state.currentObject?.account_id,
            currentTheme: state => state.currentTheme,
        }),
        hasUserdata() {
            return this.accountId && this.$store.state.currentUser?.first_name;
        }
    },
}
</script>

<style lang="less" scoped>

@import "../less/_variables";

.app-navbar {
    position: absolute;
    top: 0;
    left: 0;
    height: @navbar-height;
    width: 100%;
    background: @navbar-bg;
}

.profile-info {
    width: 42.23px;
    height: 42.23px;
    background: #F8F8F8;
    border-radius: 5px;
    cursor: pointer;
}

.badge {
    right: -5px;
    top: -5px;
}
.fas, .logout {
    color: @navbar-btn-color;
}

</style>
