import AbstractService from "./AbstractService";
import types from "../store/types";

export default class DeviceService extends AbstractService {

    constructor() {
        super();
    }

    async fetchAndStore() {
        const {code, body} = await this.request.get(this.config.apiUrl + "/v1/devices");

        if (code === 200) {
            await this.store.commit(types.DEVICES, body["hydra:member"]);
            return true;
        }

        return false;
    }

    async get(id) {
        const {code, body} = await this.request.get(this.config.apiUrl + "/v1/devices/" + id);
        return code === 200 ? body : null;
    }

    async getAndStore(id) {
        const device = await this.get(id);

        if (device) {
            await this.store.commit(types.DEVICE_UPDATE, device);
            return device;
        }

        return null;
    }

    async create({dev_eui, account_id, type, name = "", description = "", latitude = 0, longitude = 0, altitude = 0}) {
        const {code, body} = await this.request.post(this.config.apiUrl + "/v1/devices", {
            dev_eui: dev_eui.toLowerCase(),
            type: type,
            account_id: account_id,
            name: name,
            description: description,
            latitude: latitude * 1,
            longitude: longitude * 1,
            //altitude: altitude
        });

        if (code === 201) {
            await this.store.commit(types.DEVICE_ADD, body);
            return true;
        }

        return false;
    }

    async update({id, device_group = null, type, name = "", description = "", latitude = 0, longitude = 0, altitude = 0}) {
        const {code, body} = await this.request.put(this.config.apiUrl + "/v1/devices/" + id, {
            type: type,
            name: name,
            description: description,
            latitude: latitude * 1,
            longitude: longitude * 1,
            device_group: device_group,
            //altitude: altitude
        });

        if (code === 200) {
            await this.store.commit(types.DEVICE_UPDATE, body);
            return true;
        }

        return false;
    }

    async remove(id) {
        const {code} = await this.request.delete(this.config.apiUrl + "/v1/devices/" + id);

        if (code === 204) {
            await this.store.commit(types.DEVICE_REMOVE, id);
            return true;
        }

        return false;
    }
}
