<script>

import moment from "moment-mini";
import DefaultTable from "./DefaultTable";

export default {
    name: "ElectricityMeterTable",
    extends: DefaultTable,
    data() {
        return {
            columns: [
                {
                    label: this.$t('Дата'),
                    field: 'date',
                    sortable: false,
                },
                {
                    label: this.$t('Время'),
                    field: 'time',
                    sortable: false,
                },
                {
                    label: this.$t('Значение'),
                    field: 'value',
                    sortable: false,
                },
                {
                    label: this.$t('Единицы измерения'),
                    field: 'units',
                    sortable: false,
                },
                {
                    label: this.$t('Нагрузка'),
                    field: 'load',
                    sortable: false,
                }
            ]
        }
    },
    methods: {
        prepColumn(field, row) {
            if (field === "load") {

                if (row[field] === 0) {
                    return this.$t("Отключена");
                }

                if (row[field] === 1) {
                    return this.$t("Включена")
                }

                return "N/A"
            }

            return row[field];
        },
    },
    computed: {
        rows() {
            const consumption = this.timeseries?.find(item => item.getSlug() === 'consumption')?.getData()
            const load_state = this.timeseries?.find(item => item.getSlug() === 'load_state')?.getData()

            return consumption.map(item => {
                item.load = load_state.find( el => item.getDate() > el.getDate() )?.getValue()

                return {
                    date: moment(item.getDate()).format("YYYY-MM-DD"),
                    time: moment(item.getDate()).format("HH:mm:ss"),
                    value: item.getValue(),
                    units: this.metricObject?.getUnits(),
                    load: item.load
                };
            })
        },
    }
}
</script>
