import Vue from 'vue';
import Vuex from 'vuex';
import types from "./types";
import _ from "underscore";
import i18n from "../i18n";
import {DevicesSettings} from "./modules/DevicesSettings";
import StorageAdapter from "../classess/StorageAdapter";
import TimeseriesObject from "../objects/TimeseriesObject";

const storage = new StorageAdapter();

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sidebarCollapsed: storage.get("sidebarCollapsed", false),
        preloader: false,
        showSettingsMenu: false,
        simplePreloader: false,
        token: storage.get("token", ""),
        refresh: storage.get("refresh", ""),
        tileApi: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        appLocale: storage.get("appLocale", "uk"),
        appTheme: storage.get("appTheme", "light"),
        currentTheme: storage.get("currentTheme", "#6A4DD8"),
        appAvailableLocales: {
            uk: "Українська",
            ru: "Русский"
        },
        appAvailableThemes: {
            light: i18n.t("Светлая тема"),
            dark: i18n.t("Тёмная тема"),
            auto: i18n.t("Автоматическая смена темы"),
        },
        devices: [],
        types: [],
        objects: [],
        currentObject: {},
        contacts: [],
        availableContactTypes: [{
            type: 'phone',
            text: i18n.t('Телефон')
        }, {
            type: 'email',
            text: 'Email'
        }],
        currentUser: {},
        autoUpdateDevices: storage.get("autoUpdateDevices", []),
        cardsTimeseriesCache: [],
        groups: []
    },
    mutations: {
        [types.PUSH_CARD_TIMESERIES_CACHE](state, {devEui, timeseries}) {
            if (state.cardsTimeseriesCache.find(item => item.devEui === devEui)) {
                state.cardsTimeseriesCache = state.cardsTimeseriesCache.map(item => {
                    if (item.devEui === devEui) {
                        item.timeseries = timeseries.map(item => new TimeseriesObject(item));
                    }
                    return item;
                });
            } else {
                state.cardsTimeseriesCache.push({
                    devEui,
                    timeseries: timeseries.map(item => new TimeseriesObject(item))
                });
            }
        }, [types.CURRENT_OBJECT](state, payload) {
            state.currentObject = payload;
        },
        [types.CURRENT_USER](state, payload) {
            state.currentUser = payload;
        },
        [types.CONTACTS](state, payload) {
            state.contacts = payload;
        },
        [types.CONTACT_ADD](state, payload) {
            state.contacts.push(payload);
        },
        [types.CONTACT_UPDATE](state, payload) {
            state.contacts = state.contacts.map(item => item.id === payload.id ? payload : item)
        },
        [types.OBJECTS](state, payload) {
            state.objects = payload;
        },
        [types.DEVICES](state, payload) {
            state.devices = payload;
        },
        [types.DEVICE_ADD](state, payload) {
            state.devices.push(payload);
        },
        [types.DEVICE_REMOVE](state, id) {
            state.devices = _.reject(state.devices, item => item.id === id);
        },
        [types.DEVICE_UPDATE](state, payload) {
            state.devices = state.devices.map(item => item['@id'] === payload['@id'] ? payload : item);
        },
        [types.TYPES](state, payload) {
            state.types = payload;
        },
        [types.SIDEBAR_COLLAPSED](state, payload) {
            state.sidebarCollapsed = payload;
            storage.set("sidebarCollapsed", payload);
        },
        [types.PRELOADER](state, payload) {
            state.preloader = payload;
        },
        [types.SIMPLE_PRELOADER](state, payload) {
            state.simplePreloader = payload;
        },
        [types.SET_TOKEN](state, payload) {
            state.token = payload;
            storage.set("token", payload);
        },
        [types.SET_APP_LOCALE](state, payload) {
            state.appLocale = payload;
            storage.set("appLocale", payload);
        },
        [types.SET_APP_THEME](state, payload) {
            state.appTheme = payload;
            storage.set("appTheme", payload);
        },
        [types.SET_APP_THEME_COLOR](state, payload) {
            state.currentTheme = payload;
            storage.set("currentTheme", payload);
        },
        [types.SET_REFRESH](state, payload) {
            state.refresh = payload;
            storage.set("refresh", payload);
        },
        [types.SET_AUTO_UPDATE_DEVICES](state, payload) {
            state.autoUpdateDevices = payload;
            storage.set("autoUpdateDevices", payload);
        },
        [types.TOGGLE_SETTINGS](state, payload) {
            state.showSettingsMenu = payload;
        },
        [types.GROUPS](state, payload) {
            state.groups = payload;
        },
        [types.GROUP_ADD](state, payload) {
            state.groups.push(payload);
        },
        [types.GROUP_REMOVE](state, id) {
            state.groups = _.reject(state.groups, item => item.id === id);
        },
        [types.GROUP_UPDATE](state, payload) {
            state.groups = state.groups.map(item => item['@id'] === payload['@id'] ? payload : item);
        },
    },
    getters: {
        token: state => {
            return state.token;
        },
        refresh: state => {
            return state.refresh;
        },
    },
    actions: {},
    modules: {
        DevicesSettings
    },
    types: types
})
