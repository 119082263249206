<template>
    <div class="row">
        <div class="col-12 mb-4">
            <div class="alert alert-info mb-4">
                <small>{{ $t('Настройка расписания уведомлений для уровня: ') }}</small>
                <small class="font-weight-bold">"{{ levelName }}". </small>
                <small>{{ $t('Укажите ниже в какие дни и часы вы хотите получать уведомления о инцидентах.') }}</small>
            </div>
            <div class="card bg-light p-3">
                <div class="mb-3 text-center d-flex align-items-center justify-content-between">
                    <div class="font-weight-bold">{{ $t('Часы') }}</div>
                    <div>
                        <button @click="fillAllHours"
                                :class="{ 'active' : selectedHours.length === 24 }"
                                class="btn btn-sm btn-outline-purple mr-3">
                            {{ $t('Весь день') }}
                        </button>
                        <button @click="fillWorkHours"
                                :class="{ 'active' : selectedHours === [8, 9, 10, 11, 12, 13, 14, 15, 16] }"
                                class="btn btn-sm btn-outline-purple">
                            {{ $t('Рабочие часы') }}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div v-for="(chunk, index) in hoursItems" :key="'chunk' + index" class="col-12 col-md-6 col-lg-3 mb-3">
                        <div class="row">
                            <div v-for="(item) in chunk" :key="'hour' + item" class="col-12 text-center">
                                <input :checked="selectedHours.indexOf(item) > -1"
                                       class="custom-checkbox-app"
                                       :id="'hour' + item"
                                       type="checkbox" @click="changeHour(item)">
                                <label :for="'hour' + item">{{ hourLabel(item) }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mb-4">
            <div class="card bg-light p-4">
                <div class="mb-3 text-center d-flex align-items-center justify-content-between">
                    <div class="font-weight-bold">{{ $t('Дни недели') }}</div>
                    <div>
                        <button @click="selectedWeekDays = [0, 1, 2, 3, 4 ,5, 6]"
                                :class="{'active' : selectedWeekDays.length === 7}"
                                class="btn btn-sm btn-outline-purple mr-3">
                            {{ $t('Все дни') }}
                        </button>
                        <button @click="selectedWeekDays = [0, 1, 2, 3, 4]"
                                :class="{'active' : selectedWeekDays === [0, 1, 2, 3, 4]}"
                                class="btn btn-sm btn-outline-purple">
                            {{ $t('Рабочие дни') }}
                        </button>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <div v-for="(item, index) in weekDaysItems" :key="index" class="text-center mb-3">
                        <input :checked="selectedWeekDays.indexOf(item) > -1"
                               class="custom-checkbox-app"
                               :id="'weekday' + index"
                               type="checkbox">
                        <label :for="'weekDay' + index" @click="changeDay(item)">{{ dayLabel(item) }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import _ from "underscore";
import SimpleCron from "../../classess/SimpleCron";

export default {
    name: "AlarmTypeModalLevelSchedule",
    props: {
        levelIndex: {
            type: Number,
            required: true
        },
        levelData: {
            required: true
        }
    },
    data() {
        return {
            selectedHours: [],
            selectedWeekDays: [],
        }
    },
    methods: {
        changeHour(item) {
            console.log(this.selectedHours.indexOf(item) > -1)
            if (this.selectedHours.indexOf(item) > -1) {
                console.log(item, 'remove')
                this.selectedHours = _.without(this.selectedHours, item);
            } else {
                console.log(item, 'add')
                this.selectedHours.push(item);
            }
        },
        changeDay(item) {
            if (this.selectedWeekDays.indexOf(item) > -1) {
                this.selectedWeekDays = _.without(this.selectedWeekDays, item);
            } else {
                this.selectedWeekDays.push(item);
            }
        },
        fillAllHours() {
            this.selectedHours = Array.from(Array(24).keys());
        },
        fillWorkHours() {
            this.selectedHours = [8, 9, 10, 11, 12, 13, 14, 15, 16];
        },
        hourLabel(index) {
            return this.pad(index) + "-" + this.pad(index + 1)
        },
        dayLabel(index) {
            const labels = [
                this.$t('ПН'),
                this.$t('ВТ'),
                this.$t('СР'),
                this.$t('ЧТ'),
                this.$t('ПТ'),
                this.$t('СБ'),
                this.$t('ВС'),
            ]
            return labels[index];
        },
        pad(n) {
            return n < 10 ? '0' + n : n;
        }
    },
    computed: {
        levelName() {
            return this.levelData?.levelName;
        },
        hoursItems() {
            const hours = Array.from(Array(24).keys());

            return [
                hours.slice(0, 6),
                hours.slice(6, 12),
                hours.slice(12, 18),
                hours.slice(18, 24),
            ];

        },
        weekDaysItems() {
            return Array.from(Array(7).keys());
        },
        cronString() {
            const cron = new SimpleCron();

            return cron.setWeekDaysArray(this.selectedWeekDays)
                .setHoursArray(this.selectedHours)
                .getExpressionString();
        }
    },
    watch: {
        cronString(val) {
            if (this.levelIndex < 0) {
                return;
            }

            this.$emit("change", {
                levelIndex: this.levelIndex,
                cronString: val
            })
        },
        levelData: {
            deep: true,
            handler(val) {
                const cron = new SimpleCron(val?.schedule[0]);
                this.selectedWeekDays = cron.getWeekDaysArray();
                this.selectedHours = cron.getHoursArray();
            }
        }
    },
    mounted() {
        const cron = new SimpleCron(this.levelData?.schedule[0]);
        this.selectedWeekDays = cron.getWeekDaysArray();
        this.selectedHours = cron.getHoursArray();
    }
}
</script>
