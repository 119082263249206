import Request from "./Request";
import {EventBus, events} from "../EventBus";
import config from "../config"
import store from "../store"
import StorageAdapter from "./StorageAdapter";
import types from "../store/types";

export default class RequestWithAuth {

    constructor() {
        this.storage = new StorageAdapter("refresh");
        this.request = new Request();
        this.LAST_REFRESH_STATUS_KEY = "last_refresh_status";
        this.IS_REFRESHING_KEY = "is_refreshing";
    }

    async _request(url, method, data, headers) {
        const token = store.state.token;

        if (!token.length) {
            await this.unauthorizedTrigger();
            return {
                code: null,
                body: null
            };
        }

        const response = await this.request._request(url, method, data, {
            'Authorization': `Bearer ${token}`,
            ...headers
        });

        if (response.code === 401) {
            if (await this.refresh()) {
                return await this._request(url, method, data, headers);
            } else {
                await this.unauthorizedTrigger();
                return {
                    code: null,
                    body: null
                };
            }
        } else {
            return response;
        }
    }

    async get(url, data = {}, headers = {}) {
        return await this._request(url, "get", data, headers);
    }

    async post(url, data = {}, headers = {}) {
        return await this._request(url, "post", data, headers);
    }

    async put(url, data = {}, headers = {}) {
        return await this._request(url, "put", data, headers);
    }

    async patch(url, data = {}, headers = {}) {
        return await this._request(url, "patch", data, headers);
    }

    async delete(url, data = {}, headers = {}) {
        return await this._request(url, "delete", data, headers);
    }

    async unauthorizedTrigger() {
        await store.commit(types.SET_TOKEN, "");
        await store.commit(types.SET_REFRESH, "");
        EventBus.$emit(events.UNAUTHORIZED);
    }

    async refresh() {
        if (!store.state.refresh) {
            return false;
        }

        if (this.storage.get(this.IS_REFRESHING_KEY)) {
            await this.waitRefresh();
            return this.storage.get(this.LAST_REFRESH_STATUS_KEY);
        }

        this.storage.set(this.IS_REFRESHING_KEY, true);

        const {code, body} = await this.request.post(config.apiUrl + "/v1/auth/refresh", {
            refresh: store.state.refresh
        });

        if (code === 200) {
            this.storage.set(this.LAST_REFRESH_STATUS_KEY, true);
            this.storage.set(this.IS_REFRESHING_KEY, false);
            await store.commit(types.SET_TOKEN, body.token);
            await store.commit(types.SET_REFRESH, body.refresh);
            return true;
        }

        this.storage.set(this.LAST_REFRESH_STATUS_KEY, false);
        this.storage.set(this.IS_REFRESHING_KEY, false);
        return false;
    }

    async waitRefresh() {
        return await new Promise(resolve => {
            const interval = setInterval(() => {
                if (!this.storage.get(this.IS_REFRESHING_KEY)) {
                    resolve(true);
                    clearInterval(interval);
                }
            }, 500);
        });
    }
}
