<template>
    <ul class="list-group">
        <li v-for="item in accidentMetrics"
            :key="item.metric.getId()"
            class="list-group-item py-1 px-2"
        >
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <Icon :iconName="item.metric.getIcon()" :fill="item.metric.getColor()"></Icon>
                </div>
                <div class="metric-name">
                    {{ item.metric.getName() }}
                    <span v-if="item.data.port">
                       , Port: {{ item.data.port }}
                    </span>
                </div>
                <div class="metric-value">
                    {{ item.data.value }}
                    <span>{{ item.metric.getUnits() }}</span>
                </div>
            </div>
        </li>
        <li v-if="!accidentMetrics.length" class="list-group-item py-1 px-2">
            <span class="metric-name">N/A</span>
        </li>
    </ul>
</template>

<script>

    import _ from "underscore";
    import Icon from "./Icon";
    import DeviceObject from "../objects/DeviceObject";
    import metricsConfig from "../metricsConfig";

    export default {
    name: "AccidentLogContext",
    components: {
        Icon
    },
    props: {
        context: Object,
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    computed: {
        accidentMetrics() {
            return _.chain(this.context?.metrics).map(item => {
                let deviceTypeSlug = this.deviceObject?.getType()?.getSlug()
                if (metricsConfig[deviceTypeSlug]?.metricConditions?.hasOwnProperty(item.name)) {
                    item.value = metricsConfig[deviceTypeSlug]
                        ?.metricConditions[item.name]
                        ?.values
                        ?.find(el => el.value == item.value)
                        ?.name
                }
                return {
                    data: item,
                    metric: this.deviceObject.getType().getMetricBySlug(item.name)
                }
            }).uniq(item => item.data.name)
                .value();
        }
    }
}
</script>

<style lang="less" scoped>
.metric-name {
    font-weight: normal;
}

.metric-value span {
    font-weight: normal;
}

</style>
