<template>
    <div ref="deviceCard"
         v-waypoint="{ active: active, callback: onWaypoint, options: intersectionOptions }"
         class="app-device-card app-card app-box p-0">
        <div v-if="loading">
            <div class="app-device-card__preloader" >
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div v-show="!loading">
            <component :is="currentCardComponent"
                       :deviceObject="deviceObject"
                       :timeseries="timeseries"
                       :isCurrentUserDevice="isCurrentUserDevice"
                       :accidents="accidents"
                       @onLoad="() => this.loading = false"
                       @deviceDoUpdate="$emit('deviceDoUpdate', $event)"
                       @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', $event)"
                       @deviceDoRemove="$emit('deviceDoRemove', $event)"/>
        </div>

        <DefaultAutoUpdateIndicator :deviceObject="deviceObject"
                                      @iteration="refreshData"/>
    </div>
</template>

<script>

import {mapState} from "vuex";
import DeviceObject from "../../objects/DeviceObject";
import DeviceTimeseriesService from "../../services/DeviceTimeseriesService";
import TimeseriesObject from "../../objects/TimeseriesObject";
import DefaultAutoUpdateIndicator from "./default/DefaultAutoUpdateIndicator";
import DefaultCard from "./default/DefaultCard";
import metricsConfig from "../../metricsConfig";
import types from "../../store/types";
import AccidentService from "../../services/AccidentService";
import AlarmTypeService from "../../services/AlarmTypeService";

const Accident = new AccidentService();
const AlarmType = new AlarmTypeService();
const Timeseries = new DeviceTimeseriesService();

export default {
    name: "AbstractCard",
    components: {
        DefaultAutoUpdateIndicator,
        DefaultCard
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    data() {
        return {
            active: true,
            loading: true,
            timeseries: [],
            intersectionOptions: {
                threshold: [0.5, 0.5]
            },
            accidents: [],
        }
    },
    methods: {
        async loadAccidents() {
            let accidents = [];
            accidents = await Accident.fetchByDevice(this.deviceObject.getId())
            for (let index in accidents) {
                accidents[index].alarm = await AlarmType.getWitchCache(accidents[index].alarm_type.split("/").reverse()[0]);
            }
            this.accidents = accidents;
        },
        async onWaypoint({going}) {
            // if (this.active && going === this.$waypointMap.GOING_IN) {
            //     this.active = false;
            //     this.timeseries = await this.loadTimeseries();
            // }
        },
        highlightCard() {
            if (this.$route.query.highlight_device_id === this.deviceObject.getId()) {
                this.$refs.deviceCard.classList.add("highlight_card");

                setTimeout(() => {
                    this.$refs.deviceCard.classList.remove("highlight_card");
                }, 1800)
            }
        },
        async loadTimeseries() {
            this.loading = true;
            const timeseries = await Timeseries.get(this.deviceObject.getId(), null, null, null, metricsConfig[this.deviceTypeSlug]?.noLimit? null : 10);


            this.$store.commit(types.PUSH_CARD_TIMESERIES_CACHE, {
                devEui: this.deviceObject.getDevEui(),
                timeseries
            });
            this.loading = false;
            return timeseries.map(item => new TimeseriesObject(item));
        },
        async refreshData() {
            this.timeseries = await this.loadTimeseries();
        },
        async refreshOneDeviceData(devEui) {
            if (devEui === this.deviceObject?.getDevEui()) {
                this.timeseries = await this.loadTimeseries()
            }
        },
        isActiveAccident() {
            if (this.activeAccident) {
                this.$refs.deviceCard?.classList.add("highlight_card_red");
            } else {
                this.$refs.deviceCard?.classList.remove("highlight_card_red");
            }
        },
    },
    computed: {
        ...mapState({
            deviceCardAccidentsHighlight: state => state.DevicesSettings.deviceCardAccidentsHighlight,
            currentObject: state => state.currentObject,
            types: state => state.types,
            autoUpdateDevices: state => state.autoUpdateDevices
        }),
        isCurrentUserDevice() {
            return this.deviceObject.getAccountId() === this.currentObject?.account_id;
        },
        deviceTypeSlug() {
            return this.deviceObject?.getType()?.getSlug();
        },
        activeAccident() {
            return this.deviceObject.hasActiveAccidents()
        },
        currentCardComponent() {
            if (metricsConfig.hasOwnProperty(this.deviceTypeSlug) && metricsConfig[this.deviceTypeSlug]?.cardComponent) {
                return metricsConfig[this.deviceTypeSlug].cardComponent;
            }

            return DefaultCard;
        },
        // cardHeight() {
        //     return {
        //         "min-height": this.deviceCardCompactMode ? "300px" : "500px"
        //     }
        // }
    },
    watch: {
        activeAccident(val) {
            if (this.deviceCardAccidentsHighlight) {
                const method = val ? "add" : "remove";
                this.$refs.deviceCard.classList[method]("highlight_card_red");
            }
        },
        deviceCardAccidentsHighlight(val) {
            val
                ? this.activeAccident
                    ? this.$refs.deviceCard.classList.add("highlight_card_red")
                    : this.$refs.deviceCard.classList.remove("highlight_card_red")
                : this.$refs.deviceCard.classList.remove("highlight_card_red")
        },
        loading(val) {
            if (!val) {
                this.$emit("onLoad", true);
            }
        }
    },
    async mounted() {
        this.timeseries = await this.loadTimeseries();
        this.isActiveAccident();
        this.highlightCard();
        await this.loadAccidents();
        this.$eventBus.on(this.$eventBus.REFRESH_CARD_TIMESERIES, this.refreshOneDeviceData);
    },
    beforeDestroy() {
        this.$eventBus.off(this.$eventBus.REFRESH_CARD_TIMESERIES, this.refreshOneDeviceData);
    }
}
</script>

<style lang="less">
/*.app-device-card {*/
/*    width: 370px;*/
/*    position: relative;*/

/*    @media (max-width: 576px) {*/
/*        width: 100%;*/
/*        min-width: 100%;*/
/*    }*/

/*    &__preloader {*/
/*        width: 100%;*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*    }*/
/*}*/


.highlight_card {
    box-shadow: 0 0 17px #870082;
    animation: pulse .6s ease-out infinite;
}

.highlight_card_red {
    box-shadow: 0 0 17px #E94C3D;
    animation: pulse_red .6s ease-out infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 #870082;
    }
    50% {
        box-shadow: 0 0 17px #870082;
    }
    100% {
        box-shadow: 0 0 0 #870082;
    }
}

@keyframes pulse_red {
    0% {
        box-shadow: 0 0 0 #E94C3D;
    }
    50% {
        box-shadow: 0 0 17px #E94C3D;
    }
    100% {
        box-shadow: 0 0 0 #E94C3D;
    }
}
</style>
