<template>
    <div class="row">
        <h5 class="col-12 font-weight-bold pb-2">
            <Icon :iconName="metricObject.getIcon()"
                  :fill="metricObject.getColor()"
                  width="32"
                  height="32"
                  class="mr-3"/>
            <span>{{ metricObject.getName() }}</span>
            <span v-if="metricObject.getPortIndex() !== null"> #{{ metricObject.getPortIndex() + 1 }}</span>
            <span v-if="metricPort !== null"> ( port: {{ metricPort }} )</span>
        </h5>
        <div class="col-12 values-list">
            <small v-if="metricMin!== null" class="d-inline-block mr-4 mb-3">
                {{ $t('Минимальное значение') }}
                <small class="text-muted font-weight-bold">{{ metricMin }} {{ metricObject.getUnits() }}</small>
            </small>
            <small v-if="metricAvg !== null" class="d-inline-block mr-4 mb-3">
                {{ $t('Среднее значение') }}
                <small class="text-muted font-weight-bold"> - {{ metricAvg }} {{ metricObject.getUnits() }}</small>
            </small>
            <small v-if="metricMax !== null" class="d-inline-block mr-4 mb-3">
                {{ $t('Максимальное значение') }}
                <small class="text-muted font-weight-bold"> - {{ metricMax }} {{ metricObject.getUnits() }}</small>
            </small>
        </div>
    </div>
</template>

<script>
import MetricObject from "../../objects/MetricObject";
import Icon from "../Icon";

export default {
    name: "DefaultHead",
    components: {
        Icon
    },
    props: {
        metricPort: {
            type: Number,
            required: false,
            default: null
        },
        metricObject: {
            type: MetricObject,
            required: true
        },
        metricTimeseries: {
            required: true
        },
    },
    computed: {
        metricMin() {
            let minValues = this.metricTimeseries?.getData()?.map(item => item.getMin());
            let result = minValues?.length ? Math.min.apply(null, minValues) : null;
            return (!result && result !== 0) ? null : result.toFixed(2);
        },
        metricAvg() {
            let avgValues = this.metricTimeseries
                ?.getData()
                ?.map(item => item.getAvg());

            let avg = avgValues?.reduce((memo, value) => memo + value, 0);

            let result = avgValues?.length ? (avg / avgValues.length) : null;

            return (!result && result !== 0) ? null : result.toFixed(2);
        },
        metricMax() {
            let maxValues = this.metricTimeseries?.getData()?.map(item => item.getMax());
            let result = maxValues?.length ? Math.max.apply(null, maxValues) : null;
            return (!result && result !== 0) ? null : result.toFixed(2);
        },
    }
}
</script>

<style lang="less" scoped>
.values-list > small {
    background: #F4E7FF;
    padding: 10px;
    border-radius: 5px;
}
</style>
