import AbstractService from "./AbstractService";

export default class AccidentService extends AbstractService {

    constructor() {
        super();
    }

    async fetch() {
        const {code, body} = await this.request.get(this.config.apiUrl + "/v1/accidents");
        return code === 200 ? body['hydra:member'] : [];
    }

    async fetchByDevice(deviceId) {
        const {code, body} = await this.request.get(this.config.apiUrl + `/v1/devices/${deviceId}/accidents`);
        return code === 200 ? body['hydra:member'] : [];
    }

    async fetchLog(accidentId, page = 1) {
        const {code, body} = await this.request.get(this.config.apiUrl + `/v1/accidents/${accidentId}/logs`, {
            page: page,
            'order[created_at]': 'desc'
        });
        return code === 200 ? body : [];
    }
}
