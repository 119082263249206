<template>
    <Layout>
        <div class="row">
            <div class="col-12 mb-4">
                <h4 class="title">{{ $t('Обзор инцидентов') }}</h4>
            </div>
        </div>
        <AccidentsFilterButton v-if="(deviceId || (deviceId && alarmId)) && accidents.length"
                               :accidents="accidents"
                               :deviceId="deviceId"
                               :alarmId="alarmId"/>
        <div v-if="accidents.length" class="row">
            <div class="col-12 col-xxl-7 mb-4">
                <AccidentsTable :accidents="accidents"
                                @select="accident => this.currentAccident = accident"/>
            </div>
            <div class="col-12 col-xxl-5 mb-4">
                <AccidentLogTable :currentAccident="currentAccident"/>
            </div>
        </div>
        <CenterBlock v-else>
            <div class="text-center">
                <i class="far fa-check-circle text-muted fa-5x mb-4"></i>
                <h4 class="text-muted text-center">{{ $t('Все хорошо, инцидентов нет!') }}</h4>
            </div>
        </CenterBlock>
    </Layout>
</template>

<script>

import types from "../store/types";
import AccidentService from "../services/AccidentService";
import AlarmTypeService from "../services/AlarmTypeService";
import Layout from "../components/Layout";
import AccidentsTable from "../components/AccidentsTable";
import AccidentLogTable from "../components/AccidentLogTable";
import AccidentsFilterButton from "../components/AccidentsFilterButton";
import CenterBlock from "../components/CenterBlock";

const Accident = new AccidentService();
const AlarmType = new AlarmTypeService()

export default {
    name: 'Accidents',
    components: {
        AccidentsTable,
        AccidentLogTable,
        AccidentsFilterButton,
        Layout,
        CenterBlock
    },
    props: {
        deviceId: {
            type: String,
            required: false,
            default: null
        },
        alarmId: {
            required: false,
            default: null
        }
    },
    data() {
        return {
            accidents: [],
            currentAccident: null,
        }
    },
    methods: {
        async loadAccidents() {
            let accidents = [];

            accidents = this.deviceId
                ? await Accident.fetchByDevice(this.deviceId)
                : await Accident.fetch();

            if (this.alarmId) {
                accidents = accidents.filter(item => {
                    let alarmId = item.alarm_type.split("/").reverse()[0] * 1;
                    return alarmId === this.alarmId * 1
                });
            }

            for (let index in accidents) {
                accidents[index].alarm = await AlarmType.getWitchCache(accidents[index].alarm_type.split("/").reverse()[0]);
            }

            this.accidents = accidents;
            this.$store.commit(types.PRELOADER, false);
        },
    },
    async beforeRouteUpdate(to, from, next) {
        next();
        this.$nextTick(async () => {
            await this.loadAccidents();
        });
    },
    async mounted() {
        await this.loadAccidents();
    }
}
</script>

<style lang="less">

</style>
