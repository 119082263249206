<script>
import {Line} from 'vue-chartjs'
import moment from "moment-mini";
import Color from "../../classess/Color"
import MetricObject from "../../objects/MetricObject";

export default {
    name: "DefaultLineChart",
    extends: Line,
    props: {
        metricObject: {
            type: MetricObject,
            required: true
        },
        metricTimeseries: {
            required: true
        },
        timeseries: {
            required: true
        },
        selectedPeriod: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            gradient: null,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                elements: {
                    point: {
                        radius: 0,
                        hoverRadius: 10,
                        hitRadius: 5,
                        hoverBorderWidth: 4
                    },
                },
                tooltips: {
                    displayColors: true,
                    yPadding: 10,
                    xPadding: 10,
                    caretSize: 6,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    titleFontColor: '#252733',
                    bodyFontColor: '#252733',
                    borderColor: '#DFE0EB',
                    borderWidth: 1,
                    cornerRadius: 4,
                }
            }
        }
    },
    methods: {
        dateFormat(date) {
            let format = ["today", "yesterday", "before_yesterday"].indexOf(this.selectedPeriod.slug) > -1
                ? "HH:mm"
                : "YYYY-MM-DD";

            if (this.selectedPeriod.slug === "custom") {
                format = this.selectedPeriod.groupBy === "hour" ? "HH:mm" : "YYYY-MM-DD";
            }

            return moment(date).format(format);
        },
        render() {
            this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 500);
            this.gradient.addColorStop(0.1, Color.hexToRGBA(this.metricObject.getColor(), 60));
            this.gradient.addColorStop(0.6, Color.hexToRGBA(this.metricObject.getColor(), 5));
            this.chartData.datasets.map(item => item.backgroundColor = this.gradient);
            this.renderChart(this.chartData, this.options)
        },
    },
    computed: {
        chartData() {
            return {
                labels: this.labels,
                datasets: [{
                    label: this.$t("Среднее значение"),
                    data: this.datasets,
                    backgroundColor: '',
                    borderColor: this.metricObject.getColor(),
                    lineTension: 0.3,
                    borderWidth: 2,
                    borderRadius: 1000,
                    pointBackgroundColor: "#FFFFFF",
                }]
            }
        },
        labels() {
            return this.metricTimeseries
                ?.getData()
                ?.map(item => {
                    return this.dateFormat(item.getDate())
                }).reverse();
        },
        datasets() {
            return this.metricTimeseries
                ?.getData()
                ?.map(item => {
                    return item.getAvg() !== null && typeof item.getAvg() !== "undefined"
                        ? item.getAvg()
                        : item.getValue()
                })
                ?.reverse();
        }
    },
    watch: {
        metricTimeseries() {
            this.render();
        }
    },
    mounted() {
        this.render();
    }
}
</script>

<style scoped>

</style>
