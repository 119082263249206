export default {
    SIDEBAR_COLLAPSED: 'SIDEBAR_COLLAPSED',
    TOGGLE_SETTINGS: 'TOGGLE_SETTINGS',
    PRELOADER: 'PRELOADER',
    SIMPLE_PRELOADER: 'SIMPLE_PRELOADER',
    SET_TOKEN: 'SET_TOKEN',
    SET_REFRESH: 'SET_REFRESH',
    DEVICES: 'DEVICES',
    DEVICE_UPDATE: 'DEVICE_UPDATE',
    DEVICE_ADD: 'DEVICE_ADD',
    DEVICE_REMOVE: 'DEVICE_REMOVE',
    TYPES: 'TYPES',
    OBJECTS: 'OBJECTS',
    CURRENT_OBJECT: 'CURRENT_OBJECT',
    CONTACTS: 'CONTACTS',
    CONTACT_ADD: 'CONTACT_ADD',
    CONTACT_UPDATE: 'CONTACT_UPDATE',
    CURRENT_USER: 'CURRENT_USER',
    SET_APP_LOCALE: 'SET_APP_LOCALE',
    SET_APP_THEME: 'SET_APP_THEME',
    SET_APP_THEME_COLOR: 'SET_APP_THEME_COLOR',
    SET_SHOW_MAP: 'SET_SHOW_MAP',
    DEVICE_CARD_ACCIDENTS_HIGHLIGHT: 'DEVICE_CARD_ACCIDENTS_HIGHLIGHT',
    SET_CARD_DESIGN: 'SET_CARD_DESIGN',
    SET_AUTO_UPDATE_DEVICES: 'SET_AUTO_UPDATE_DEVICES',
    PUSH_CARD_TIMESERIES_CACHE: 'PUSH_CARD_TIMESERIES_CACHE',
    GROUPS: 'GROUPS',
    GROUP_UPDATE: 'GROUP_UPDATE',
    GROUP_ADD: 'GROUP_ADD',
    GROUP_REMOVE: 'GROUP_REMOVE',
}
