<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <h4 class="title mb_40">
                    {{ $t('Аварии') }}
                </h4>
            </div>
            <div v-if="alarms.length" class="col-12 mb-4">
                <vue-good-table
                    :columns="columns"
                    :rows="alarms"
                    :pagination-options="vgtPaginationOptions()">
                    <template slot="table-row" slot-scope="props">
                        <small v-if="props.column.field === 'alarm'">
                            <a href="#"
                               class="font-weight-bold"
                               @click.prevent="$router.push({name: 'DeviceAlarm', params: { id: getDevice(props.row.device).id},
                                        query: { highlight_alarm_id :  props.row.id }})">
                                {{ props.row.title | stringLimit }}
                            </a>
                        </small>
                        <small v-else-if="props.column.field === 'device'">
                            <a href="#"
                               class="font-weight-bold d-block"
                               @click.prevent="$router.push({name: 'Devices', query: { highlight_device_id : getDevice(props.row.device).id }})">
                                {{ getDevice(props.row.device).name | stringLimit }}
                            </a>
                            <span class="text-muted d-block"> {{ getDevice(props.row.device).description | stringLimit }}</span>
                        </small>
                        <small class="font-weight-bold text-muted" v-else-if="props.column.field === 'count_conditions'">
                            {{ countAlarmConditions(props.row) }}
                        </small>
                        <small v-else-if="props.column.field === 'action'">
                                <span class="btn-group btn-block">
                                    <button @click="toAccidents(props.row)" class="btn btn-sm btn-purple">
                                        {{ $t('Обзор инцидентов') }}
                                    </button>
                                    <button @click="removeTypeModalVisible = true; currentAlarmType = props.row"
                                            class="btn btn-sm btn-danger">
                                        {{ $t('Удалить') }}
                                    </button>
                                </span>
                        </small>
                        <div v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </div>
                    </template>
                </vue-good-table>
            </div>
            <div class="col-12" v-else>
                <CenterBlock positionType="relative">
                    <div class="text-center app-card">
                        <i class="far fa-bell text-muted fa-5x mb-4"></i>
                        <h4 class="text-muted text-center mb-4">{{ $t('Вы еще не добавляли условий для аварий') }}</h4>
                        <button @click="$router.push({name: 'Devices'})" class="btn btn-outline-purple">{{ $t('Перейти к устройствам') }}</button>
                    </div>
                </CenterBlock>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb_40">
                <h4 class="title">{{ $t('Обзор инцидентов') }}</h4>
            </div>
        </div>
        <div v-if="accidents.length" class="row">
            <div class="col-12 col-xxl-7 mb-4">
                <AccidentsTable :accidents="accidents"
                                @select="accident => this.currentAccident = accident"/>
            </div>
            <div class="col-12 col-xxl-5 mb-4">
                <AccidentLogTable :currentAccident="currentAccident"/>
            </div>
        </div>
        <div class="row position-relative" v-else>
            <CenterBlock class="col-12" positionType="relative">
                <div class="text-center app-card">
                    <i class="far fa-check-circle text-muted fa-5x mb-4"></i>
                    <h4 class="text-muted text-center">{{ $t('Все хорошо, инцидентов нет!') }}</h4>
                </div>
            </CenterBlock>
        </div>

        <RemoveModal :opened="removeTypeModalVisible"
                     :processing="removeProcessing"
                     :title="$t('Удалить аварию?')"
                     :text="$t('Вы уверены что хотите удалить аварию и все журналы инцидентов? Это действие нельзя будет отменить')"
                     :buttonText="$t('Удалить')"
                     @removed="removeAlarmType"
                     @closed="() => this.removeTypeModalVisible = false"/>
    </Layout>
</template>

<script>

import _ from "underscore";
import types from "../store/types";
import {mapState} from "vuex";
import Helper from "../classess/Helper";
import Icon from "../components/Icon";
import RemoveModal from "../components/modals/RemoveModal";
import AlarmTypeService from "../services/AlarmTypeService";
import config from "../config";
import Layout from "../components/Layout";
import CenterBlock from "../components/CenterBlock";
import AccidentService from "../services/AccidentService";
import AccidentsTable from "../components/AccidentsTable";
import AccidentLogTable from "../components/AccidentLogTable";
import AccidentsFilterButton from "../components/AccidentsFilterButton";

const Accident = new AccidentService();
const AlarmType = new AlarmTypeService();

export default {
    name: 'Alarms',
    components: {
        RemoveModal,
        Icon,
        Layout,
        CenterBlock,
        AccidentsTable,
        AccidentLogTable,
        AccidentsFilterButton,
    },
    filters: {
        stringLimit: str => Helper.stringLimit(str, 30)
    },
    data() {
        return {
            removeTypeModalVisible: false,
            removeProcessing: false,
            currentAlarmType: null,
            alarms: [],
            columns: [
                {
                    label: this.$t('Устройство'),
                    field: 'device',
                    sortable: false,
                },
                {
                    label: this.$t('Авария'),
                    field: 'alarm',
                    sortable: false,
                },
                {
                    label: this.$t('Количество условий в аварии'),
                    field: 'count_conditions',
                },
                {
                    label: this.$t('Действия'),
                    field: 'action',
                    sortable: false,
                    width: '300px'
                },
            ],
            accidents: [],
            currentAccident: null,
        }
    },
    methods: {
        vgtPaginationOptions: config.vgtPaginationOptions,
        getDevice(device) {
            const id = device?.split("/")?.reverse()[0];

            return _.find(this.devices, device => {
                return device.id === id;
            }) || {};
        },
        countAlarmConditions(alarm) {
            const countConditions = _.reduce(alarm.create_rules, (memo, item) => {
                return memo + item.conditions?.length;
            }, 0);

            return countConditions + " " + Helper.wordForms(countConditions, [
                this.$t('условие'),
                this.$t('условия'),
                this.$t('условий'),
            ])
        },
        toAccidents(alarmType) {
            this.$router.push({
                name: 'Accidents',
                params: {
                    deviceId: alarmType?.device?.split('/')?.reverse()[0],
                    alarmId: alarmType.id
                }
            })
        },
        async removeAlarmType() {
            this.removeProcessing = true;

            if (await AlarmType.remove(this.currentAlarmType.id)) {
                this.alarms = (await AlarmType.fetch()).reverse();
                this.$toast.success(this.$t("Авария удалена!"));
            } else {
                this.$toast.error(this.$t("Ошибка удаления!"));
            }

            this.removeTypeModalVisible = false;
            this.removeProcessing = false;
        },
        async loadAccidents() {
            let accidents = [];

            accidents = await Accident.fetch();

            if (this.alarmId) {
                accidents = accidents.filter(item => {
                    let alarmId = item.alarm_type.split("/").reverse()[0] * 1;
                    return alarmId === this.alarmId * 1
                });
            }

            for (let index in accidents) {
                accidents[index].alarm = await AlarmType.getWitchCache(accidents[index].alarm_type.split("/").reverse()[0]);
            }

            this.accidents = accidents;
            this.$store.commit(types.PRELOADER, false);
        },
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        }),
    },
    async beforeRouteUpdate(to, from, next) {
        next();
        this.$nextTick(async () => {
            await this.loadAccidents();
        });
    },
    async mounted() {
        this.alarms = (await AlarmType.fetch()).reverse();
        await this.loadAccidents();
        this.$store.commit(types.PRELOADER, false);
    }
}
</script>
