<template>
    <div class="auto-update-indicator">
        <div class="auto-update-indicator__progress-bar"
             :style="{ width: progressBarWidth }">
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import DeviceObject from "../../../objects/DeviceObject";
import config from "../../../config";

export default {
    name: "DefaultAutoUpdateIndicator",
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    data() {
        return {
            updateLoop: null,
            interval: config.cardAutoUpdateInterval,
            iterator: 0
        }
    },
    methods: {
        handleLoop() {
            if (this.isEnabledAutoUpdate) {
                this.updateLoop = setInterval(() => {
                    this.iterator++;
                    if (this.iterator === this.interval) {
                        this.iterator = 0;
                        this.$emit("iteration");
                    }
                }, 1000);
            } else {
                clearInterval(this.updateLoop);
                this.iterator = 0;
            }
        }
    },
    computed: {
        ...mapState({
            autoUpdateDevices: state => state.autoUpdateDevices
        }),
        isEnabledAutoUpdate() {
            return this.autoUpdateDevices.indexOf(this.deviceObject.getId()) !== -1;
        },
        progressBarWidth() {
            return (this.iterator / this.interval) * 100 + "%";
        }
    },
    watch: {
        isEnabledAutoUpdate() {
            this.$nextTick(this.handleLoop);
        }
    },
    mounted() {
        this.handleLoop();
    }
}
</script>

<style lang="less" scoped>

.auto-update-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: transparent;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;

    &__progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: fade(#732AAD, 70);
        transition: 1s linear;
    }
}

</style>
