import GerkonStatus from "../src/components/deviceCharts/GerkonStatus";
import AirQualityHeating from "../src/components/deviceCharts/AirQualityHeating";
import ValveStatus from "./components/deviceCharts/ValveStatus";
import ElectricityMeter from "./components/deviceCharts/ElectricityMeter";
import i18n from "./i18n";
import LeakStatus from "./components/deviceCharts/LeakStatus";

/**
 *  "device_type_slug": {
 *       chartMetricsBlacklist:    ["metric_slug_1", ....], Скрытые метрики для страницы /device/xxxxxxxxxxxx....
 *       notAggregatedMetrics:     ["metric_slug_1", ....],
 *       tabledMetrics:            ["metric_slug_1", ....],
 *       cardComponent:            () => import("..."),
 *       chartsComponent:          ["metric_slug_1", ...., GerkonStatus], Кастомные компоненты ГРАФИКОВ для страницы /device/xxxxxxxxxxxx....
 *       metricConditions: {
 *           "metric_slug": {
 *               items: ["eq", ...],
 *               values: [{
 *                   name: <string>,
 *                   value: <string>
 *               }]
 *           }
 *       },
 *       limit: 10   Кастомный лимит загрузки Timeseries
 *   }
 */
export default {
    "lds_xxxx": {
        chartMetricsBlacklist: [],
        notAggregatedMetrics: ["status", "times", "last_duration_min"],
        tabledMetrics: ["status", "times", "last_duration_min"],
        cardComponent: () => import("./components/deviceCards/gerkon/GerkonCard"),
        chartsComponent: ["status", GerkonStatus],
        metricConditions: {
            "status": {
                items: ["eq", "neq"],
                values: [{
                    name: i18n.t("Закрыто"),
                    value: "0"
                }, {
                    name: i18n.t("Открыто"),
                    value: "1"
                }]
            }
        }
    },
    "lwl_xxxx": {
        chartMetricsBlacklist: [],
        notAggregatedMetrics: ["status", "times", "last_duration_min"],
        tabledMetrics: ["status", "times", "last_duration_min"],
        cardComponent: () => import("../src/components/deviceCards/leak/LeakCard"),
        chartsComponent: ["status", LeakStatus],
        metricConditions: {
            "status": {
                items: ["eq", "neq"],
                values: [{
                    name: i18n.t("Нет протечки"),
                    value: "0"
                }, {
                    name: i18n.t("Есть протечка"),
                    value: "1"
                }]
            }
        }
    },
    "air_quality_xxxx_heating": {
        chartMetricsBlacklist: [],
        notAggregatedMetrics: ["heating"],
        tabledMetrics: ["heating"],
        cardComponent: () => import("../src/components/deviceCards/heating/HeatingCard"),
        chartsComponent: ["heating", AirQualityHeating],
    },
    "dragino_lt_relay_xxxx": {
        chartMetricsBlacklist: ["ro1", "ro2"],
        notAggregatedMetrics: ["res"],
        tabledMetrics: ["res"],
        cardComponent: () => import("../src/components/deviceCards/valve/ValveCard"),
        chartsComponent: ["res", ValveStatus]
    },
    "mtx_electricity_meter_x_xxxx": {
        chartMetricsBlacklist: ["load_state"],
        notAggregatedMetrics: ["consumption", "load_state"],
        tabledMetrics: ["consumption", "load_state"],
        cardComponent: () => import("./components/deviceCards/electricityMeter/ElectricityMeterCard"),
        chartsComponent: ["consumption", ElectricityMeter],
        noLimit: true
    }
}
