<template>
    <div v-if="metric" class="condition-item">
        <div class="row">
            <div class="col-2">
                <Icon
                    :icon-name="metric.getIcon()"
                    :fill="metric.getColor()"
                    width="20"
                    height="20"
                />
            </div>
            <div class="col-10 small-text">
                <small class="">{{ $t('Если') }} </small>
                <small class="font-weight-bold">"{{ metric.getName() }}" </small>
                <small class="">{{ conditionTypeText }}: </small>
                <small class="font-weight-bold">{{ conditionValue }} </small>
                <small class="">{{ metric.getUnits() }}</small>
            </div>
        </div>
    </div>
</template>

<script>

import config from "../config";
import Icon from "./Icon";
import DeviceObject from "../objects/DeviceObject";
import metricsConfig from "../metricsConfig";

export default {
    name: "AlarmTypeCardConditions",
    components: {
        Icon
    },
    props: {
        condition: Object,
        index: Number,
        deviceObject: {
            type: DeviceObject,
            required: true
        },
    },
    computed: {
        metric() {
            return this.deviceObject.getType().getMetricBySlug(this.condition.metric_name);
        },
        conditionTypeText() {
            return config.conditionsList[this.condition.type]
                .replace(/\((.*)/, '')
                .toLowerCase();
        },
        conditionValue() {
            let customValueText = "";
            let deviceTypeSlug = this.deviceObject?.getType()?.getSlug();

            if (metricsConfig[deviceTypeSlug]?.metricConditions?.hasOwnProperty(this.condition?.metric_name)) {
                customValueText = metricsConfig[deviceTypeSlug]
                    ?.metricConditions[this.condition?.metric_name]
                    ?.values
                    ?.find(item => item.value === this.condition.value)?.name;
            }

            return customValueText || this.condition.value;
        }
    }
}
</script>

<style lang="less" scoped>
.small-text > * {
    font-size: .7rem;
}


</style>
