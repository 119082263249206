<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div ref="modalDialog" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Редактирование устройства') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened && !visibleMap" class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            Dev EUI
                            <strong class="text-danger">*</strong></label>
                        <div class="input-group mb-3">
                            <input ref="devEui" type="text" class="form-control" readonly v-model="device.dev_eui">
                            <div class="input-group-append">
                                <button @click="copyDevEui" class="btn btn-secondary">
                                    <i class="far fa-copy fa-fw"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            Dev ID
                            <strong class="text-danger">*</strong></label>
                        <div class="input-group mb-3">
                            <input ref="devId" type="text" class="form-control" readonly v-model="device.id">
                            <div class="input-group-append">
                                <button @click="copyDevId" class="btn btn-secondary">
                                    <i class="far fa-copy fa-fw"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Тип устройства') }}
                            <strong class="text-danger">*</strong></label>
                        <select v-model="device.type" class="form-control">
                            <option v-for="type in types"
                                    :key="type.slug"
                                    :value="type['@id']">
                                {{ type.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Имя устройства') }}
                            <strong class="text-danger">*</strong></label>
                        <input v-model="device.name" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Описание устройства') }}
                            <strong class="text-danger">*</strong></label>
                        <textarea v-model="device.description" class="form-control" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Группа') }}
                        </label>
                        <select v-model="device.device_group" class="form-control">
                            <option :value=null >
                                {{ $t('Без группы') }}
                            </option>
                            <option v-for="group in groups"
                                    :key="group.id"
                                    :value="group['@id']">
                                {{ group.name }}
                            </option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="font-weight-bold text-muted">
                                    {{ $t('Широта') }}
                                    <strong class="text-danger">*</strong></label>
                                <input v-model="device.latitude" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="font-weight-bold text-muted">
                                    {{ $t('Долгота') }}
                                    <strong class="text-danger">*</strong></label>
                                <input v-model="device.longitude" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="opened && visibleMap" class="modal-body">
                    <Map style="border-radius: .5rem" :markers="markers" :visibleGetCurrentPositionBtn="true" @change="setCoords"/>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button @click="visibleMap = !visibleMap" class="btn"
                            :class="{ 'btn-success' : !visibleMap, 'btn-danger' : visibleMap }">
                        <i v-if="!visibleMap" class="fas fa-map-marked-alt pr-2"></i>
                        {{ !visibleMap ? $t('Показать карту') : $t('Выбрать') }}
                    </button>
                    <button v-if="!visibleMap"
                            @click="update"
                            :disabled="processing || !valid"
                            :style="{ width : '150px' }"
                            class="btn btn-purple">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import _ from "underscore";
import $ from "jquery";
import {mapState} from 'vuex';
import Map from "../Map";
import DeviceService from "../../services/DeviceService";
import DeviceObject from "../../objects/DeviceObject";

const Device = new DeviceService();

export default {
    name: "DeviceUpdateModal",
    components: {
        Map
    },
    props: {
        opened: Boolean,
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    data() {
        return {
            processing: false,
            visibleMap: false,
            device: {
                id: null,
                type: "",
                name: "",
                description: "",
                latitude: 0,
                longitude: 0,
                device_group: null
            }
        }
    },
    methods: {
        copyDevId() {
            this.$refs.devId.select();
            document.execCommand("copy");
            this.$toast.success(this.$t('Значение скопировано в буфер обмена'))
        },
        copyDevEui() {
            this.$refs.devEui.select();
            document.execCommand("copy");
            this.$toast.success(this.$t('Значение скопировано в буфер обмена'))
        },
        setCoords({latitude, longitude}) {
            this.device.latitude = latitude;
            this.device.longitude = longitude;
        },
        async update() {
            this.processing = true;
            const result = await Device.update({
                ...this.device,
                type: _.find(this.types, type => type['@id'] === this.device.type)['@id']
            });
            this.processing = false;

            if (result) {
                this.$toast.success(this.$t('Параметры устройства обновлены!'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
    },
    computed: {
        ...mapState({
            types: state => state.types,
            currentObject: state => state.currentObject,
            groups: state => state.groups,
        }),
        valid() {
            return this.device.type
                && this.device.name
                && this.device.description
                && this.device.latitude
                && this.device.longitude
        },
        markers() {
            return [{
                latitude: this.device.latitude || 0,
                longitude: this.device.longitude || 0
            }];
        }
    },
    watch: {
        visibleMap(val) {
            if (val) {
                this.$refs.modalDialog.classList.add('modal-lg');
            } else {
                this.$refs.modalDialog.classList.remove('modal-lg');
            }
        },
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
                this.device.id = this.deviceObject.getId();
                this.device.dev_eui = this.deviceObject.getDevEui();
                this.device.type = this.deviceObject.getType().getId();
                this.device.name = this.deviceObject.getName();
                this.device.description = this.deviceObject.getDescription();
                this.device.latitude = this.deviceObject.getLatitude();
                this.device.longitude = this.deviceObject.getLongitude();
                this.device.device_group = this.deviceObject.getGroup();
            } else {
                Object.assign(this.$data, this.$options.data());
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style scoped>

</style>
