<template>
    <div class="accidents-info position-fixed">
        <div class="backdrop" @click="$emit('closed')"></div>
        <div class="section p-3">
            <div class="row mb-5">
                <div class="col-10 font-weight-bold d-flex align-items-center">
                    <h5 class="mb-0">{{ deviceObject.getName() }}</h5>
                    <div class="accident-indicator cursor-pointer ml-3" v-if="hasActiveAccidents">
                        <div class="accident-indicator__pulse"></div>
                        <div class="accident-indicator__pulse2"></div>
                        <Icon icon-name="danger"
                              fill="#FF0000"
                              width="24"
                              height="24"/>
                    </div>
                    <div class="accident-indicator cursor-pointer ml-3" v-else>
                        <Icon icon-name="danger"
                                      fill="#B9B9BA"
                                      width="24"
                                      height="24"/>
                    </div>
                </div>
                <div class="col-2">
                    <button type="button" class="close" @click="$emit('closed')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div class="row font-weight-500">
                <div class="col-6">{{ $t('Широта') }}</div>
                <div class="col-6">{{ deviceObject.getLatitude() }}</div>
            </div>
            <div class="row font-weight-500">
                <div class="col-6">{{ $t('Долгота') }}</div>
                <div class="col-6">{{ deviceObject.getLongitude() }}</div>
            </div>
        </div>
        <div class="section font-weight-500 px-3 py-2" v-for="(metric, index) in deviceMetrics" :key="index">
            <div class="row">
                <div class="col-6">{{ $t('Название метрики') }}</div>
                <div class="col-6">{{ metric.getName() }}</div>
            </div>
            <div class="row">
                <div class="col-6">{{ $t('Показатели') }}</div>
                <div class="col-6">{{ getMetricLabel(metric) }}</div>
            </div>
            <div class="row">
                <div class="col-6">{{ $t('Інциденти') }}</div>
                <div class="col-6"
                     :class="{'text-danger': metricWithAccident.includes(metric.getSlug())}">
                    {{ metricHasAccident(metric) ? $t('Есть активный инцидент') : $t('Нет активного инцидента') }}
                </div>
            </div>
        </div>
        <button @click="$router.push({name: 'Accidents', params: {deviceId:  deviceId }})"
                style="width: 150px;"
                class="btn btn-block btn-purple mx-auto cursor-pointer mt-5">
            {{ $t('Детали') }}
        </button>
    </div>
</template>

<script>

import Icon from "../../Icon";
import DeviceObject from "../../../objects/DeviceObject";

export default {
    name: "AccidentsInfo",
    components: {
        Icon
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: false
        },
        accidents: {
            type: Array,
            required: false
        },
    },
    data() {
        return {
            metricWithAccident: []
        }
    },
    computed: {
        hasActiveAccidents() {
            return this.deviceObject.hasActiveAccidents()
        },
        deviceId() {
            return this.deviceObject.getId();
        },
        deviceMetrics() {
            return this.deviceObject?.getType()?.getMetricsWithPortsExclude(["bat_volt"])
        },
    },
    methods: {
        metricHasAccident(metric) {
            this.accidents?.forEach(accident => {
                accident.alarm.create_rules[0].conditions.forEach(rule => {
                    if(metric?.getSlug() === rule.metric_name) {
                        if(!this.metricWithAccident.includes(metric?.getSlug())) {
                            this.metricWithAccident.push(metric?.getSlug())
                        }
                    }
                })
            });
            return this.metricWithAccident.includes(metric?.getSlug())
        },
        getMetricLabel(metric, fixed = 2) {
            const values = this.timeseries?.filter(item => item.getSlug() === metric?.getSlug());
            let deviceTypeSlug = this.deviceObject?.getType()?.getSlug();
            const value = metric?.getPortIndex()
                ? values[metric?.getPortIndex()]
                : values[0];

            const result = value?.getLatestData()?.getValue();
            if (metric.getSlug() === 'status' || metric.getSlug() === 'res') {
                if (deviceTypeSlug === 'lwl_xxxx') {
                    return result === 1 ? this.$t('Обнаружено') : this.$t('Не обнаружено')
                }
                if (deviceTypeSlug === 'lds_xxxx' || deviceTypeSlug === 'dragino_lt_relay_xxxx') {
                    return result === 1 ? this.$t('Открыто') : this.$t('Закрыто')
                }
            }
            if (metric.getSlug() === 'heating' || metric.getSlug() === 'load_state') {
                return result === 1 ? this.$t('Включено') : this.$t('Выключено')
            }

            return (result || result === 0) ? result?.toFixed(fixed) + " " + metric.getUnits() : "N/A";
        },
    },
    watch: {
        hasActiveAccidents(val) {
            this.$emit('onHasAccidents', val);
        }
    },
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.accidents-info {
    height: 100vh;
    width: 520px;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
    box-shadow: -15px 4px 24px rgba(51, 51, 51, 0.04), -15px 5px 24px rgba(51, 51, 51, 0.1);
}

.section {
    border-bottom: 1px solid #D8D8D8;

    &:last-of-type {
        border-bottom: none;
    }
}

.backdrop {
    left: unset;
    right: 100%;
}

.accident-indicator {
    position: relative;
    z-index: 1;

    &__pulse, &__pulse2 {
        display: block;
        content: "";
        background: tint(#E94C3D, 50);
        border-radius: 100%;
        width: 28px;
        height: 28px;
        animation-name: pulse;
        animation-duration: 1.5s;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        z-index: -1;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -14px;
        margin-left: -14px;
    }

    &__pulse2 {
        animation-delay: 350ms;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}
</style>
