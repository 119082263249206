import AbstractService from "./AbstractService";

export default class DeviceMetricAccessService extends AbstractService {

    constructor() {
        super();
    }

    async create(metricName, port, deviceAccess) {
        const {code} = await this.request.post(this.config.apiUrl + "/v1/device_metric_accesses", {
            name: metricName,
            port: port,
            device_access: deviceAccess
        });
        return code === 201;
    }

    async update(id, metricName, port) {
        const {code} = await this.request.put(this.config.apiUrl + "/v1/device_metric_accesses/" + id, {
            name: metricName,
            port: port,
        });
        return code === 200;
    }

    async remove(id) {
        const {code} = await this.request.delete(this.config.apiUrl + "/v1/device_metric_accesses/" + id);
        return code === 204;
    }
}