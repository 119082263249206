module.exports = {
    "Авария включена": "",
    "Авария выключена": "",
    "Авария удалена!": "",
    "Без группы": "",
    "Включить аварию": "",
    "Вы ещё не создали группу": "",
    "Выберите группу": "",
    "Группа": "",
    "Группа переименованна": "",
    "Группа создана": "",
    "Группа удалена": "",
    "Группа c таким именем уже создана": "",
    "Детали": "",
    "Добавить группу": "",
    "Есть активный инцидент": "",
    "Інциденти": "",
    "Название метрики": "",
    "Название новой группы": "",
    "Настроить доступ": "",
    "Настройки приложения": "",
    "Нет активного инцидента": "",
    "Отключить аварию": "",
    "Ошибка": "",
    "Ошибка удаления!": "",
    "Переименовать": "",
    "По данному фильтру устройств не обнаруженно": "",
    "Поиск датчика по имени": "",
    "Показатели": "",
    "Редактирование групп": "",
    "Редактировать группы": "",
    "Создать группу": "",
    "Состояние": "",
    "Управление": "",
    "Управление приложением": "",
    "Устройство добавленно в группу": "",
    "Устройство удаленно из группы": "",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ": "",
    "ВС": "НД",
    "ВТ": "ВТ",
    "из": "із",
    "ПН": "ПН",
    "ПТ": "ПТ",
    "СБ": "СБ",
    "СР": "СР",
    "т.": "т.",
    "ЧТ": "ЧТ",
    "ш.": "ш.",
    "ал.": "ал.",
    "Время": "Час",
    "Все": "Усе",
    "Действия": "Дії",
    "пл.": "пл.",
    "аллея": "алея",
    "б-р.": "б-р.",
    "Вход": "Вхід",
    "Дата": "Дата",
    "Если": "Якщо",
    "Имя": "Ім'я",
    "Імя": "Ім'я",
    "Сюда": "Сюди",
    "Туда": "Туди",
    "ул.": "вул.",
    "условий": "умов",
    "шоссе": "шосе",
    "Port": "Port",
    "Вчера": "Вчора",
    "въезд": "в'їзд",
    "Выйти": "Вийти",
    "Карта": "Карта",
    "Логин": "Логін",
    "Назад": "Назад",
    "Обзор": "Огляд",
    "пер.": "пров.",
    "площадь": "площа",
    "пр-т.": "пр-т.",
    "Район": "Район",
    "Событие": "Подія",
    "условие": "умова",
    "Условие": "Умова",
    "условия": "умови",
    "Аварии": "Аварії",
    "Авария": "Аварія",
    "Выбрать": "Обрати",
    "Дизайн": "Дизайн",
    "Добавить": "Додати",
    "Месяц": "Місяць",
    "Пароль": "Пароль",
    "Статус": "Статус",
    "Страна": "Країна",
    "улица": "вулиця",
    "Уровень": "Рівень",
    "Часы": "Години",
    "Широта": "Широта",
    "бульвар": "бульвар",
    "Все дни": "Усі дні",
    "Высокий": "Високий",
    "Главная": "Головна",
    "Графики": "Графіки",
    "Долгота": "Довгота",
    "Доступы": "Доступи",
    "Метрика": "Метрика",
    "Неделя": "Тиждень",
    "Низкий": "Низький",
    "Область": "Область",
    "Править": "Правити",
    "Профиль": "Профіль",
    "Телефон": "Телефон",
    "Авария: ": "Аварія: ",
    "Выключено": "Вимкнено",
    "Закрыто": "Зачинено",
    "Значение": "Значення",
    "Контакты": "Контакти",
    "Контекст": "Контекст",
    "Обнаружено": "Виявлено",
    "Обновлено": "Оновлено",
    "Отключена": "Вимкнено",
    "Ошибка!": "Помилка!",
    "переулок": "провулок",
    "проспект": "проспект",
    "проулок": "провулок",
    "сад.мас.": "сад.мас.",
    "Сегодня": "Сьогодні",
    "Создано": "Створено",
    "Сохранить": "Зберегти",
    "Средний": "Середній",
    "Страница": "Сторінка",
    "Удалить": "Видалити",
    "Устройства": "Пристрої",
    "Устройство": "Пристрій",
    "Устройство:": "Пристрій",
    "Фамилия": "Прізвище",
    "Агрегация": "Агрегація",
    "Весь день": "Весь день",
    "Включена": "Увімкнено",
    "Включено": "Увімкнено",
    "Дни недели": "Дні тижня",
    "жил.мас.": "житл.мас.",
    "Завершено": "Завершено",
    "Инциденты": "Інциденти",
    "Критический": "Критичний",
    "Меньше (<)": "Менше (<)",
    "Открыто": "Відчинено",
    "Поддержка": "Підтримка",
    "Позавчера": "Позавчора",
    "Аккаунт ID": "Аккаунт ID",
    "Больше (>)": "Більше (>)",
    "На главную": "На головну",
    "Нормальный": "Нормальний",
    "Рабочие дни": "Робочі дні",
    "Регистрация": "Реєстрація",
    "Редактировать": "Редагувати",
    "Статистика": "Статистика",
    "Тёмная тема": "Темна тема",
    "тупик": "глухий кут",
    "Устройство: ": "Пристрій: ",
    "Авторизация": "Авторизація",
    "Бухгалтерия": "Бухгалтерія",
    "Закрылось": "Зачинилося ",
    "Не найдено": "Не знайдено",
    "Не обнаружено": "Не виявлено",
    "Нет данных": "Немає даних",
    "Открылось": "Відчинилося",
    "Светлая тема": "Світла тема",
    "ID Аккаунта": "ID Аккаунта",
    "Все устройства": "Усі пристрої",
    "Выполнение...": "Виконання...",
    "Добавить условие": "Додати умову",
    "Есть протечка": "Є протікання",
    "Нагрузка": "Навантаження",
    "Название аварии": "Назва аварії",
    "Настройки": "Налаштування",
    "Новый пароль": "Новий пароль",
    "Обновить данные": "Оновити дані",
    "Обновление...": "Оновлення...",
    "Равно (=)": "Дорівнює (=)",
    "Тип устройства": "Тип пристрою",
    "Условия аварии": "Умови аварії",
    "Атентификация": "Атентификація",
    "Без агрегации": "Без агрегації",
    "Добавить аварию": "Додати аварію",
    "Добавить уровень": "Додати рівень",
    "Добавление аварии": "Додати аварію",
    "Имя устройства": "Ім'я пристрою",
    "Описание устройства": "Опис пристрою",
    "Отдел продаж": "Відділ продаж",
    "Пароль еще раз": "Пароль ще раз",
    "Рабочие часы": "Робочі години",
    "садовый массив": "садовий масив",
    "Добавить контакт": "Додати контакт",
    "жилой массив": "житловий масив",
    "Качество воздуха": "Якість повітря",
    "Показать карту": "Показати карту",
    "Авария удалена": "Аварія видалена",
    "Алексей Веретюк": "Олексій Веретюк",
    "Датчик для агро": "Датчик для агро",
    "Добавить устройство": "Додати пристрій",
    "Доступ открыт": "Доступ відкрито",
    "Зарегистрироваться": "Зареєструватися",
    "Контакт добавлен": "Контакт доданий",
    "Меньше контактов": "Менше контактів",
    "Населенный пункт": "Населений пункт",
    "Открыть доступ": "Відкрити доступ",
    "Тема интерфейса": "Тема інтерфейсу",
    "Язык интерфейса": "Мова інтерфейсу",
    "Больше контактов": "Більше контактів",
    "Восстановить пароль": "Відновити пароль",
    "Выбрать диапазон": "Вибрати діапазон",
    "Доступ удален": "Доступ видалений",
    "Команда выполнена": "Команда виконана",
    "Контакт Обновлён!": "Контакт оновлено",
    "Местоположение": "Місцезнаходження",
    "Напряжение батареи:": "Напруга батареї:",
    "Не равно (!=)": "Не дорівнює (!=)",
    "Нет протечки": "Немає протікання",
    "Обзор инцидентов": "Огляд інцидентів",
    "Открытые метрики": "Відкриті метрики",
    "Среднее значение": "Середнє значення",
    "Технические вопросы": "Технічні питання",
    "Удалить аварию?": "Видалити аварію?",
    "Удалить доступ?": "Видалити доступ?",
    "Устройство добавлено!": "Пристрій додано!",
    "Выберете мессенджер": "Оберіть месенджер",
    "Изменить положение": "Змінити положення",
    "Перейти к аварии": "Перейти до аварії",
    "Удалить устройство": "Видалити пристрій",
    "Установить пароль": "Встановити пароль",
    "Устройство удалено": "Пристрій видалено",
    "Войти через Google": "Войти через Google",
    "Восстановление пароля": "Відновлення паролю",
    "Добавление контакта": "Додавання контакту",
    "Добавление устройства": "Додавання пристрою",
    "Доступ закрыт": "Доступ заборонений",
    "Доступ к устройству": "Доступ до пристрою",
    "Доступ открыт с": "Доступ відкритий з",
    "Настройки обновлены": "Параметри оновлено",
    "Новый пароль еще раз": "Новий парол ще раз",
    "Параметры обновлены": "Параметри оновлені",
    "Позиция определена!": "Позицію визначено!",
    "Произвольный диапазон": "Довільний діапазон",
    "Просто хорошие люди": "Просто хороші люди",
    "Редактирование аварии": "Редагування аварії",
    "Строк на страницу": "Рядків на сторінці",
    "Удалить устройство?": "Видалити пристрій?",
    "Выбрать диапазон дат": "Обрати діапазон дат",
    "Доступ открыт до": "Доступ відкритий до",
    "Единицы измерения": "Одиниці вимірювання",
    "Заполните все поля!": "Заповніть усі поля!",
    "Количество закрытий": "Кількість закриттів",
    "Команда отправлена": "Команда відправлена",
    "Минимальное значение": "Мінімальне значення",
    "Настройка аварий": "Налаштування аварій",
    "Настройки аварий": "Налаштування аварій",
    "Перейти к устройству": "Перейти до пристрою",
    "Применен фильтр": "Застосований фільтр",
    "Расписание уведомлений": "Розклад повідомлень",
    "Управление доступом": "Управління доступом",
    "Количество открытий": "Кількість відкриттів",
    "Максимальное значение": "Максимальне значення",
    "Настройка доступа": "Налаштування доступу",
    "Настройки доступа": "Налаштування доступу",
    "Обнаружена протечка!": "Виявлено протікання!",
    "Перейти к устройствам": "Перейти до пристроїв",
    "Редактирование устройства": "Редагування пристрою",
    "Условие аварии добавлено!": "Умову аварії додано!",
    "Я уже зарегистрирован": "Я вже зараєстрований",
    "Авторизация разрешена": "Авторизація дозволена",
    "Выкл нагрузку": "Вимкнути навантаження",
    "Компактный вид карточек": "Компактний вид карток",
    "Позиция не определена!": "Позицію не визначено!",
    "Разрешить авторизацию": "Дозволити авторизацію",
    "Автоматическая смена темы": "Автоматична зміна теми",
    "Авторизация запрещена": "Авторизація заборонена",
    "Вкл нагрузку": "Увімкнути навантаження",
    "Протечка не обнаружена": "Протікання не виявлено",
    "Количество условий в аварии": "Кількість умов в аварії",
    "Меньше или равно (<=)": "Менше або дорівнює (<=)",
    "Отключить авто-обновление": "Вимкнути авто-оновлення",
    "Пароль успешно изменен": "Пароль успішно змінений",
    "Больше или равно (>=)": "Більше або дорівнює (>=)",
    "Включить авто-обновление": "Увімкнути авто-оновлення",
    "Дизайн карточек интерфейса": "Дизайн карток інтерфейсу",
    "Ваш персональний менеджер": "Ваш персональний менеджер",
    "Местоположение устройства": "Місцезнаходження пристрою",
    "Неверный логин или пароль": "Невірний логін або пароль",
    "Вы еще не добавляли контакты": "Ви ще не додавали контакти",
    "Вы еще не добавляли устройства": "Ви ще не додавали пристрої",
    "Онлайн-чат с техподдержкой": "Онлайн чат з техпідтримкою",
    "К каким метрикам даем доступ?": "До яких метрик даємо доступ?",
    "Команда на изменение отправлена": "Команда на зміну відправлена",
    "Параметры устройства обновлены!": "Параметри пристрою оновлені!",
    "Все хорошо, инцидентов нет!": "Все гаразд, інцидентів немає!",
    "На сколько открываем доступ?": "На скільки відкриваємо доступ?",
    "Редактирование доступа к устройству": "Редагування доступу до пристрою",
    "Вы еще не добавляли условий для аварий": "Ви ще не додавали умов для аварій",
    "Подсветка карточек с инцидентами": "Підсвічування карток з інцидентами",
    "Токовые петли + счетчик электричества": "Токові петлі + лічильник електрики",
    "Значение скопировано в буфер обмена": "Значення скопійовано у буфер обміну",
    "На этом устройстве есть активные инциденты": "На цьому пристрої є активні інциденти",
    "Необходимо выбрать минимум одну метрику": "Необхідно вибрати мінімум одну метрику",
    "Вы еще не открывали доступ к устройствам": "Ви ще не відкривали доступ до пристроїв",
    "Датчик давления воды + управление задвижками": "Датчик тиску води + керування засувками",
    "Доступ к статистике устройства открыт!": "Доступ до статистики пристрою відкритий!",
    "Ошибка! Возможно такой контакт уже есть": "Помилка! Можливо такий контакт вже існує",
    "На этом устройстве нет активных инцидентов": "На цьому пристрої немає активних інцидентів",
    "Настройка расписания уведомлений для уровня: ": "Налаштування розкладу повідомлень для рівня: ",
    "Инструкция для восстановления пароля отправлена на": "Інструкція для відновлення пароля відправлена на",
    "Удалить уровень? Так же будут удалены все условия этого уровня": "Видалити рівень? Так само будуть видалені всі умови цього рівня",
    "Авария состоится, если все нижеперечисленные условия будут выполнены": "Аварія відбудеться, якщо всі перелічені нижче умови будуть виконані",
    "Вы уверены что хотите удалить доступ? Это действие нельзя будет отменить!": "Ви впевнені що хочете видалити доступ? Ця дія не можна буде скасована!",
    "Вы уверены что хотите удалить устройство? Это действие нельзя будет отменить!": "Ви впевнені що хочете видалити пристрій? Цю дію не можна буде скасувати!",
    "Укажите ниже в какие дни и часы вы хотите получать уведомления о инцидентах.": "Вкажіть нижче в які дні та години ви хочете отримувати повідомлення про інциденти.",
    "Вы уверены что хотите удалить аварию и все журналы инцидентов? Это действие нельзя будет отменить": "Ви впевнені що хочете видалити аварію та всі журнали інцидентів? Цю дію не можна буде скасувати!",
    "Здесь можно добавить свои контакты в профиль. Так же можно включить возможность авторизации, используя контакт как логин.": "Тут можна додати свої контакти в профіль. Також можна включити можливість авторизації, використовуючи контакт як логін.",
    "Вы можете открыть доступ к устройству другим пользователям. Укажите ID аккаунта, и выберите метрики которыми вы хотите поделится": "Ви можете відкрити доступ до пристрою іншим користувачам. Вкажіть ID аккаунта, і виберіть метрики якими ви хочете поділитися"
}