import AbstractService from "./AbstractService";
import types from "../store/types";

export default class GroupService extends AbstractService {

    constructor() {
        super();
    }

    async fetchAndStore() {
        const {code, body} = await this.request.get(this.config.apiUrl + "/v1/device_groups");

        if (code === 200) {
            await this.store.commit(types.GROUPS, body["hydra:member"]);
            return true;
        }

        return false;
    }

    async get(id) {
        const {code, body} = await this.request.get(this.config.apiUrl + "/v1/device_groups/" + id);
        return code === 200 ? body : null;
    }

    async getAndStore(id) {
        const group = await this.get(id);

        if (group) {
            await this.store.commit(types.GROUPS, group);
            return group;
        }

        return null;
    }

    async create(account_id, groupName) {
        const {code, body} = await this.request.post(this.config.apiUrl + "/v1/device_groups", {
            account_id: account_id,
            name: groupName,
        });

        if(code === 422) {
            return code
        }

        if (code === 201) {
            await this.store.commit(types.GROUP_ADD, body);
            return body;
        }

        return false;
    }

    async update({id ,name}) {
        const {code, body} = await this.request.put(this.config.apiUrl + "/v1/device_groups/" + id, {
            name: name,
        });

        if (code === 200) {
            await this.store.commit(types.GROUP_UPDATE, body);
            return true;
        }

        return false;
    }

    async remove(id) {
        const {code} = await this.request.delete(this.config.apiUrl + "/v1/device_groups/" + id);

        if (code === 204) {
            await this.store.commit(types.GROUP_REMOVE, id);
            return true;
        }

        return false;
    }
}
