<template>
    <div class="position-fixed settings-item">
        <div class="settings-item-header p-4">
            <div class="row">
                <div class="col-10 text-center font-weight-bold">
                    <h5>{{ $t('Управление приложением') }}</h5>
                </div>
                <div class="col-2">
                    <button type="button" class="close" @click="$emit('closed')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="settings-item-body p-4">
                <div class="d-flex align-items-center justify-content-between mb-4 border rounded">
                    <div class="pl-3">
                        {{ $t('Карта') }}
                    </div>
                    <toggle-button :sync="true"
                                   @click.native.stop
                                   class="rounded-right"
                                   :color="toggleColor"
                                   :width="34"
                                   :height="14"
                                   :switch-color="switchColor"
                                   :value="showMap"
                                   @change="e => onChangeShowMap(e)"/>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-4 border rounded">
                    <div class="pl-3">
                        {{ $t('Подсветка карточек с инцидентами') }}
                    </div>
                    <toggle-button :sync="true"
                                   @click.native.stop
                                   class="rounded-right"
                                   :color="toggleColor"
                                   :width="34"
                                   :height="14"
                                   :switch-color="switchColor"
                                   :value="deviceCardAccidentsHighlight"
                                   @change="e => onChangeHighLight(e)"/>
                </div>
                <div class="mb-4">
                    <label>{{ $t('Язык интерфейса') }}</label>
                    <select @change="setLocale" class="form-control rounded">
                        <option v-for="(locale, index) in appAvailableLocales"
                                :key="index"
                                :selected="index === appLocale"
                                :value="index">
                            {{ locale }}
                        </option>
                    </select>
                </div>
                <div class="mb-4">
                    <label>{{ $t('Тема интерфейса') }}</label>
                    <select @change="setTheme" class="form-control rounded">
                        <option v-for="(theme, index) in appAvailableThemes"
                                :key="index"
                                :selected="index === appTheme"
                                :value="index">
                            {{ theme }}
                        </option>
                    </select>
                </div>
            </div>
            <button style="width: 150px;" @click="saveAllSettings" class="btn btn-purple mx-auto btn-block" :disabled="processing">
                {{ $t('Сохранить') }}
            </button>
        <div class="backdrop" @click="$emit('closed')"></div>
    </div>
</template>

<script>

import {mapState} from "vuex";
import config from "../../config";
import types from "../../store/types";

export default {
    name: "SettingsAPP",
    data: () => ({
        selectedLocale: null,
        selectedTheme: null,
        selectedDesign: null,
        processing: false
    }),
    methods: {
        setLocale(e) {
            this.selectedLocale = e.target.value;
        },
        setTheme(e) {
            this.selectedTheme = e.target.value;
        },
        onChangeShowMap(e) {
            this.$store.commit(types.SET_SHOW_MAP, e.value)
        },
        onChangeHighLight(e) {
            this.$store.commit(types.DEVICE_CARD_ACCIDENTS_HIGHLIGHT, e.value)
        },
        saveAllSettings() {
            this.processing = true;
            if (this.selectedLocale) {
                this.$store.commit(types.SET_APP_LOCALE, this.selectedLocale);
            }
            if (this.selectedTheme) {
                this.$store.commit(types.SET_APP_THEME, this.selectedTheme);
            }
            this.processing = false;
        },
    },
    computed: {
        ...mapState({
            showMap: state => state.DevicesSettings.showMap,
            deviceCardAccidentsHighlight: state => state.DevicesSettings.deviceCardAccidentsHighlight,
            appAvailableLocales: state => state.appAvailableLocales,
            appLocale: state => state.appLocale,
            appAvailableThemes: state => state.appAvailableThemes,
            appTheme: state => state.appTheme,
            currentTheme: state => state.currentTheme,
        }),
        toggleColor() {
            return config.toggleColor;
        },
        switchColor() {
            return config.switchColor;
        },
    },
}
</script>

<style scoped lang="less">
    @import "../../less/_variables";

    .settings-item {
        width: 495px;
        background: white;
        height: 100%;
        z-index: 1001;
        margin-left: @settings-menu-width;
        box-shadow: 0 4px 4px rgba(51, 51, 51, 0.04), 0px 5px 24px rgba(51, 51, 51, 0.1);
        top: 0;

        &-header {
            border-bottom: 1px solid #D8D8D8;
        }
    }

</style>
